input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.form-label {
    margin-bottom: 0.5rem;
    display: flex;
    font-family: sans-serif;
    font-size: 18px!important;
    line-height: 26px;
    font-weight: 400!important;
}

.contactformnext{
    background: linear-gradient(180.68deg, rgba(234, 228, 255, 0) -3.36%, #EDE8FF 83.75%);
    border-radius: 0px;
    min-height: 400px;
    position: relative;
    top: 100px;
}

.contacthankyou {
    width: 1300px;
    margin: auto;
    padding: 2rem 2.5rem;
    height: auto;
}

.thankcol h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 100px;
    display: block;
    align-items: flex-end;
    letter-spacing: 0.01em;
    color: #2D2D2D;
}
.thankcolbtn{
    margin-bottom:50px
    
}
.thankcol p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    display: block;
    text-align: justify;
    letter-spacing: 0.01em;
    color: #2D2D2D;
}

.contacthankyou.row {
    margin: auto;

    width: 800px;
    padding: 2rem 2.5rem;
    height: auto;
}


.formcontactnext{
    width: 800px;
    height: auto;
    margin: auto;
    padding: 2rem 2.5rem;
}

.firstheadingcontact h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 60px;
    display: block;
    align-items: flex-end;
    letter-spacing: 0.01em;
    color: #2D2D2D;
}

.firstheadingcontact p{
    text-transform: none;
letter-spacing: 0.5px;
font-size: 20px;
line-height: 20px;
font-weight: 400;
}
.contactformbtn{
    position: static;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 32px !important;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    width: max-content;
    flex-grow: 0;
    margin: 0 2rem;
    background: #008AFF;
    border-radius: 4px;
    border: hidden;
    padding: 10px 28px !important;
}

@media only screen and (max-width: 400px){
    .thankcolbtn{
        margin-left: -20px;
    }
}

@media only screen and (max-width: 850px){
    .firstheadingcontact h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 35px;
        display: block;
        align-items: flex-end;
        letter-spacing: 0.01em;
        color: #2D2D2D;
    }

    .formcontactnext {
        width: 100%;
        height: auto;
        margin: auto;
        padding: 2rem 2.5rem;
    }

    .thankcol h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 20px;
        display: block;
        align-items: flex-end;
        letter-spacing: 0.01em;
        color: #2D2D2D;
    }
    .thankcol p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 30px;
        display: block;
        text-align: justify;
        letter-spacing: 0.01em;
        color: #2D2D2D;
    }
    .contacthankyou.row {
        margin: auto;
        width: 100%;
        padding: 2rem 2.5rem;
        height: auto;
    }
}