@import url('https://fonts.googleapis.com/css2?family=Poppins');


#blue:hover{
   background-color: #F1B0FF !important;
   
}

#red:hover{
    background-color: #C4B8FE !important;
}

#green:hover{
    background-color: #9CA9FF !important;
}

#grey:hover{
    background-color: #9CD2FF !important;
}

#yellow:hover{
    background-color: #C3B8FE !important;
}

#orange:hover{
    background-color: #F1ADFF !important;
}

.hover-container:hover *{
   color: white;
   filter: brightness(0) invert(1);
}




.dotoneservices{
    position: absolute;
    width: 8px;
    height: 8px;
    left: -10%;
    bottom: 60%;
    border-radius: 50%;
    background: #6EBECE;
    top: 65%;
}

.dottwoservices{
    position: absolute;
    width: 15px;
    height: 15px;
    right: 0%;
    top: 95%;
    border-radius: 50%;
    background: #FF99D0;
}

.dotthreeservices{
    position: absolute;
    width: 7px;
    height: 7px;
    left: 90%;
    right: 0%;
    top: 70%;
    border-radius: 50%;
    background: #ff5775;
}

.dotfourservices{

    position: absolute;
    width: 15px;
    height: 15px;
    /* left: 0; */
    top: 80%;
    right: 2%;
    border-radius: 50%;
    background: #A9D8FF;
}

.dotfiveservices{
    position: absolute;
    width: 5px;
    height: 5px;
    /* left: 0; */
    top: 88%;
    left: -20%;
    /* right: 0%; */
    border-radius: 50%;
    background: #BA0CDC;
}








.header-image{

width: 100%;
height: auto;
left: 0px;
top: 100px;
position: relative;
margin-bottom: 100px;
background: linear-gradient(180deg, #D8EDFF 0%, #F6EBFF 100%);
}


    .colimage {
        width: 35%!important;
        margin: left;
        margin: auto;
        top: 70px;
        left: 25px;
        text-align: left;
        height: auto;
        position: relative;
    }


.coltext{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #747474;
}

span{
    font-weight: 600;
    
}
.text{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 50px;
}

.render-servise-list{
    display: flex;
    width: 400px;
    height: auto;
    border: 1px solid;
    /* width: 400px; */
    /* height: 73px; */
    position: relative;
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    color: #ABABAB;
    padding: 0.5rem 0rem;
    margin-bottom: 3%;
    cursor: pointer;

}




/* .render-servise-list .render-service-text:hover{
  
    color: white;
}
.render-servise-list:hover{
  
  
    transition: all 0.5s;
    background: #F1B0FF;
    
} */
.main-div{
    display: flex;
}

.sidetext{
    padding: 0rem 2rem;
}

.render-service-text{
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    text-align: left;
    width: 100%;
    padding: 0rem 2rem;
    color: #ABABAB;
    margin: auto;
}

.main-services{
    position: relative;
    display: inline-block;
    margin: auto;
    width: 1280px;
    height: auto;
    /* border: 1px solid black; */
   
    justify-content: center;
    padding: 2.5rem 2rem;
}


.img-service-list{
    position: relative;
    width: 25px;
    height:auto;
    left: 50%;

}

.line{
width: 95%;
height: 0px;
border: 0.5px solid #CCCCCC;
margin: 1%;
}

.img-text button
{
    position: static;
    width: fit-content;
    height: 30px;
    left: 0px;
    /* top: 236px; */
    background: #008AFF;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    border: none;
    line-height: 24px;
    color: white;
}

.tag-h4 h4{
    text-align: left;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
   margin-top: 40px;
    line-height: 36px;
    color: #2D2D2D;
}

.contain-img-text img{
    width: 100%;
    position: relative;
    /* left: -10%; */
    padding: 0.5rem 0rem;
}

.img-text p{
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.01em;
    color: #747474;
    text-align: left;
    width: 100%;
}

.img-text button{
    float: left;
    height: auto;
    padding: 12px 24px;
}

.sidetext h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    color: #2D2D2D;
    text-align: left;
}

.sidetext h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    color: #2D2D2D;
    text-align: left;
}

.sidetext img{
    align-items: center;
    padding: 0px;
    gap: 10px;
    width: 100%;
    height: auto;
    background: #F7F7F7;
    border-radius: 4px;
}

.sidetext p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #747474;
    text-align: left;
    padding: 1rem 0rem;
}

/* media queries */


@media (max-width: 576px) { 

    .text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 40px;
    }

    .sidetext h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 40px;
        color: #2D2D2D;
        text-align: left;
        margin-top: 80px;
    }
    .header-image {
        position: relative;
        width: 100%;
        height: auto;
        top: 50px;
        left: 0px;
        background: linear-gradient(180deg, #D8EDFF 0%, #F6EBFF 100%);
    }
    .main-div {
        display: block;
    }
    .sidetext {
        top: 3rem;
        /* padding: 0rem 2rem; */
        position: relative;
      
        height: auto;
    }
    .main-services {
        position: relative;
        display: inline-block;
        margin: auto;
        width: 90%;
    }

    .colimage {
        top:25px
    }

    .coltext {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        padding: 10px 10px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #747474;
    }

    .render-service-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        text-align: left;
        width: 100%;
        padding: 0rem 2rem;
        color: #ABABAB;
        margin: auto;
    }
    .sidetext p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 25px;
        letter-spacing: 0.01em;
        color: #747474;
        text-align: left;
        padding: 1rem 0rem;
    }

    .render-servise-list {
        display: flex;
        width: 100%;
        height: auto;
        border: 1px solid;
        /* width: 400px; */
        /* height: 73px; */
        position: relative;
        border-radius: 4px;
        flex: none;
        order: 0;
        flex-grow: 0;
        color: #ABABAB;
        padding: 0.5rem 0rem;
        margin-bottom: 3%;
        cursor: pointer;
    }
}


@media only screen and (min-width: 576px) and (max-width: 1199px){

    .sidetext h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 40px;
        color: #2D2D2D;
        text-align: left;
        margin-top: 80px;
    }
    .coltext {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #747474;
        width: 508px;
    }

    .render-servise-list {
        display: flex;
        width: 100%;
        height: auto;
        border: 1px solid;
        /* width: 400px; */
        /* height: 73px; */
        position: relative;
        border-radius: 4px;
        flex: none;
        order: 0;
        flex-grow: 0;
        color: #ABABAB;
        padding: 0.5rem 0rem;
        margin-bottom: 3%;
        cursor: pointer;
    }

    .header-image {
        position: relative;
        width: 100%;
        height: auto;
        left: 0px;
        top: 50px;
        background: linear-gradient(180deg, #D8EDFF 0%, #F6EBFF 100%);
    }
    .main-div {
        display: block;
    }
    .sidetext {
        top: 3rem;
        /* padding: 0rem 2rem; */
        position: relative;
    }
    .main-services {
        position: relative;
        display: inline-block;
        margin: auto;
        width: 80%;
    }

    .colimage {
        top:0px
    }
}


@media only screen and (min-width: 767px) and (max-width: 1200px){

.colimage {
    width: 25%!important;
    margin: auto;
    top: 0px;
    left: 75px;
    text-align: left;
    height: auto;
    position: relative;
}
}



@media only screen and (min-width:1280px) and (max-width:1599px) {
     

    .main-services{
        position: relative;
        display: inline-block;
        margin: auto;
        width: 1200px;
        height: auto;
        /* border: 1px solid black; */
       
        justify-content: center;
        padding: 2.5rem 2rem;
    }

    .serviceimages{
        width: 1250px;
        height: 800px;
    
        margin: auto !important;
    }

    .sidetext {
       
        margin: 0px 5rem;
       
        width: 625px;
        height: auto;
    }

    .main-service-image{
        width: 100%;
        height: 820px;
        object-fit: cover;
    }

    .render-servise-list{
        display: flex;
        width: 400px;
        height: 73px;
        border: 1px solid;
        /* width: 400px; */
        /* height: 73px; */
        position: relative;
        border-radius: 4px;
        flex: none;
        order: 0;
        flex-grow: 0;
        color: #ABABAB;
        padding: 0.5rem 0rem;
        margin-bottom: 3%;
        cursor: pointer;
    
    }
    

    .colimage {
        width: 50%!important;
        margin: left;
        margin: auto;
        top: 158px;
        left: 80px;
        text-align: left;
        height: auto;
        position: relative;
    }

    .header-image{
        
        width: 100%;
        height: 820px;
        left: 0px;
  
        background: linear-gradient(180deg, #D8EDFF 0%, #F6EBFF 100%);
        }
    .text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 70px;
    line-height: 85px;
    }
    .coltext {
        font-family: 'Poppins';

        color: #747474;
        font-weight: 400;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.01em;
        width: 450px;
    }

    .render-service-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        text-align: left;
        width: 100%;
        padding: 0rem 2rem;
        color: #ABABAB;
        margin: auto;
    }

    .sidetext h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 72px;
        color: #2D2D2D;
        text-align: left;
    }

    .line {
        width: 400px;
        height: 0px;
        border: 0.5px solid #CCCCCC;
        margin: 1%;
    }

    .sidetext p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.01em;
        color: #747474;
        text-align: left;
        padding: 1rem 0rem;
    }
    .img-text p {
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        letter-spacing: 0.01em;
        color: #747474;
        text-align: left;
        width: 85%;
    }

    .img-service-list{
        position: relative;
        width: 35px;
        height:auto;
        left: 50%;
        top: 15%;
    }

    
}


@media only screen  and (min-width : 1600px) {
    .sidetext {
       
        margin: 0px 5rem;
       
        width: 700px;
        height: auto;
    }

    .header-image {
        width: 100%;
        height: 820px;
        left: 0px;
        background: linear-gradient(180deg, #D8EDFF 0%, #F6EBFF 100%);
    }
     
    .main-service-image {
        width: 700px;
        height: 820px;
        object-fit: cover;
    }
    .serviceimages{
        width: 1280px;
        height: 800px;
        
        margin: auto !important;
    }
    .sidetext {
        top: 0rem;
        /* padding: 0rem 2rem; */
        position: relative;
        width: 775px;
        height: auto;
    }
    .text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 60px;
        line-height: 70px;
    }

    .render-service-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 18px;
        text-align: left;
        width: 100%;
        padding: 0rem 2rem;
        color: #ABABAB;
        margin: auto;
    }
    .coltext {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #747474;
    }


    .sidetext h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 35px;
        line-height: 40px;
        color: #2D2D2D;
        text-align: left;
    }

    .sidetext h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 35px;
        line-height: 40px;
        color: #2D2D2D;
        text-align: left;
    }

    .sidetext p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.01em;
        color: #747474;
        text-align: left;
        padding: 1rem 0rem;
    }

    .img-service-list {
        position: relative;
        width: 32px;
        height: auto;
        left: 50%;
        top: 10px;
    
    }

    .colimage {
        width: 50%!important;
        margin: left;
        margin: auto;
        top: 170px;
        left: 25px;
        text-align: left;
        height: auto;
        position: relative;
    }

    .img-text p {
        font-weight: 400;
        font-size: 15px;
        line-height: 23px;
        letter-spacing: 0.01em;
        color: #747474;
        text-align: left;
        width: 100%;
    }

    .coltext {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 26px;
        letter-spacing: 0.01em;
        color: #747474;
        width: 420px;
    }

    .render-servise-list {
        display: flex;
        /* width: 70%;
        height: auto; */
        border: 1px solid;
         width: 400px; 
         height: 73px; 
        position: relative;
        border-radius: 4px;
        flex: none;
        order: 0;
        flex-grow: 0;
        color: #ABABAB;
        padding: 0.5rem 0rem;
        margin-bottom: 3%;
        cursor: pointer;
    }

    .sidetext img {
        align-items: center;
        padding: 0px;
        gap: 10px;
        width: 710px;
        height: 280px;
        background: #F7F7F7;
        border-radius: 4px;
    }

    .contain-img-text img {
        width: 400px;
        height: 240px;
        position: relative;
        left: 0%;
        padding: 0.5rem 0rem;
    }
    .line {
        width: 390px;
        height: 0px;
        border: 0.5px solid #CCCCCC;
        margin: 1%;
    }

    .sidetext img {
        /* padding: 0px; */
        position: relative;
        /* gap: 10px; */
        width: 710px;
      
        height: 280px;
        background: #F7F7F7;
        border-radius: 4px;
    }
}
