@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600");
@import '../styles/Styleguid.css';


.take-action-litigation{
position: relative;
position: relative;
display: flex;
margin: auto;
justify-content: center;
/* padding: 30px 0px; */
}

.taketestlit button {
  align-items: flex-start;
  padding: 0px 14px;
  /* gap: 10px; */
  width: 5.18rem;
  height: 27px;
  font-size: 10px;
  color: white;
  border: none;
  /* height: 48px; */
  background: #008AFF;
  border-radius: 4px;
}

.header-image-litigation{
    
    width: 100%;
    height: 880px;
    left: 0px;
    background: linear-gradient(180deg, #D8EDFF 0%, #F6EBFF 100%);
}

.coliimage-litigation{
    width: 33rem !important;
    margin: left;
    
    top: 145px;
    
    text-align: left;
    height: 33rem;
    position: relative;
}

.textlitigation{
    font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 70px;
line-height: 85px;



color: #2D2D2D;
}

.coltextlitigation{
    
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 32px;
letter-spacing: 0.01em;

color: #747474;

}

.imagelitigation-banner img{
  margin-top: 10rem;
  width: 620px;
  height: 428px;
}

.restorelitigation{
    position: relative;
    display: flex;
    margin: auto;
    width: 1200px !important;
    top: 100px;
    height: auto;
    justify-content: center;
    
}

.imagelitigation{
  margin-bottom: 100px;
}


.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

.valign-text-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.valign-text-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.litigation {
  align-items: center;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  height: 4406px;
  overflow: hidden;
  width: 1920px;
}

.flex-col {
  align-items: center;
  display: flex;
  flex-direction: column;
  
  width: 1920px;
}

.overlap-group3 {
  height: 73px;
  position: relative;
  width: 1692px;
}

.ellipse-2 {
  background-color: var(--azure-radiance);
  border-radius: 4px;
  height: 8px;
  left: 1178px;
  position: absolute;
  top: 52px;
  width: 8px;
}

.header {
  align-items: center;
  display: flex;
  height: 73px;
  left: 0;
  min-width: 1692px;
  position: absolute;
  top: 0;
}

.logo-aprils-credit-1 {
  height: 73px;
  width: 163px;
}

.frame-32 {
  align-items: flex-end;
  display: flex;
  height: 18px;
  margin-bottom: 11px;
  margin-left: 590px;
  min-width: 588px;
}

.place {
  letter-spacing: 0;
  margin-bottom: -8px;
  min-height: 26px;
  min-width: 48px;
}

.our-services {
  letter-spacing: 0;
  margin-bottom: -8px;
  margin-left: 32px;
  min-height: 26px;
  min-width: 100px;
}

.faq {
  letter-spacing: 0;
  margin-bottom: -8px;
  margin-left: 32px;
  min-height: 26px;
  min-width: 32px;
}

.resources {
  letter-spacing: 0;
  margin-bottom: -8px;
  margin-left: 32px;
  min-height: 26px;
  min-width: 84px;
}

.pricing {
  letter-spacing: 0;
  margin-bottom: -8px;
  margin-left: 32px;
  min-height: 26px;
  min-width: 74px;
}

.contact-us {
  letter-spacing: 0;
  margin-bottom: -8px;
  margin-left: 32px;
  min-height: 26px;
  min-width: 90px;
}

.frame-31 {
  align-items: center;
  background-color: var(--azure-radiance);
  border-radius: 8px;
  display: flex;
  height: 56px;
  left: 1432px;
  min-width: 222px;
  padding: 0 24px;
  position: absolute;
  top: 8px;
}

.free-online-consultation {
  letter-spacing: 0.18px;
  line-height: 32px;
  min-height: 32px;
  min-width: 174px;
  text-align: center;
  white-space: nowrap;
}

.icon-call {
  height: 25px;
  left: 1447px;
  position: absolute;
  top: 21px;
  width: 25px;
}

.overlap-group4 {
 
  background: linear-gradient(180deg, #D8EDFF 0%, #F6EBFF 100%);

  height: 820px;
  position: relative;
  width: 100%;
}

.line-1 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.help-you-bannercontent {
    align-items: center;
    background-size: 100% 100%;
    display: flex;
    height: 820px;
    justify-content: flex-end;
    left: 0;
    margin: auto;
    max-width: 1920px;
    padding: 108px 32px;
    position: absolute;
    top: 0;
    text-align: left;
}

.frame-35 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  min-height: 535px;
  width: 532px;
}

.title {
  letter-spacing: 0;
  line-height: 85px;
 
  width: 483px;
}

.span2 {
  color: var(--heavy-metal);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 400;
}

.aprils-credit-repair {
  letter-spacing: 0.2px;
  line-height: 32px;
  margin-top: 24px;
  min-height: 256px;
  width: 532px;
}

.litigationscreen{
    /* position: relative;
    display: inline-block;
    margin: auto;
    width: 85%;
    height: auto;
    border: 1px solid black;
    justify-content: center;
    padding: 0rem 2rem; */
}

.litigation-1 {
    height: 330px;
    margin-top: 28px;
    object-fit: cover;
    width: 100%;
}

.flex-row {
  align-items: flex-start;
  display: flex;
  height: 332px;
  margin-right: 98px;
  margin-top: 110px;
  min-width: 1086px;
}

.frame-62 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 332px;
  padding: 10px 0;
  width: 605px;
}

.help-you-avoid-any-litigation {
  height: 32px;
  letter-spacing: 0.36px;
  line-height: 32px;
  margin-right: 18px;
  margin-top: 10px;
  white-space: nowrap;
  width: 567px;
}

.aprils-credit-repair-1 {
  height: 224px;
  letter-spacing: 0.2px;
  line-height: 32px;
  margin-top: 46px;
  width: 585px;
}

.overlap-group {
  height: 277px;
  margin-left: 146px;
  margin-top: 10px;
  position: relative;
  width: 335px;
}

.ellipse-10 {
  background-color: 
#e3f2ff;
  border-radius: 130px;
  height: 260px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 260px;
}

.x5-1 {
  height: 277px;
  left: 37px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 298px;
}

.flex-row-1 {
  align-items: center;
  display: flex;
  height: 498px;
  margin-left: 113px;
  margin-top: 100px;
  min-width: 1077px;
}

.overlap-group1 {
  height: 350px;
  position: relative;
  width: 337px;
}

.ellipse-11 {
  background-color: 
#ede8ff;
  border-radius: 130px;
  height: 260px;
  left: 0;
  position: absolute;
  top: 0;
  width: 260px;
}

.x6-1 {
  height: 233px;
  left: 72px;
  position: absolute;
  top: 14px;
  width: 265px;
}

.frame-63 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 135px;
  min-height: 498px;
  padding: 10px 0;
  width: 605px;
  
}

.keep-good-credit-cards-open {
  height: 90px;
  letter-spacing: 0.36px;
  line-height: 32px;
  margin-right: 8px;
  width: 577px;
}

.many-settlement-comp {
  letter-spacing: 0.2px;
  line-height: 32px;
  margin-top: 36px;
  min-height: 352px;
  width: 585px;
}

.flex-row-2 {
  align-items: center;
  display: flex;
  height: 388px;
  margin-right: 129px;
  margin-top: 179px;
  min-width: 1061px;  
    margin-top: 0px;
}

.frame-64 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 388px;
  padding: 10px;
  width: 607px;
}

.charge-a-one-time-payment {
  height: 32px;
  letter-spacing: 0.36px;
  line-height: 32px;
  margin-top: 10px;
  white-space: nowrap;
}

.we-charge-a-one-time {
  letter-spacing: 0.2px;
  line-height: 32px;
  margin-top: 38px;
  min-height: 288px;
  width: 585px;
}

.overlap-group6 {
  height: 260px;
  margin-left: 139px;
  position: relative;
  width: 315px;
}

.ellipse-12 {
  background-color: 
#dfd9ff;
  border-radius: 130px;
  height: 260px;
  left: 0;
  position: absolute;
  top: 0;
  width: 260px;
}

.x8png-copy-1 {
  height: 252px;
  left: 35px;
  object-fit: cover;
  position: absolute;
  top: 4px;
  width: 280px;
}

.flex-row-3 {
  align-items: flex-start;
  display: flex;
  height: 454px;
 
  margin-top: 100px;
  /* margin-top: 80px 0px;
  margin-top: 80px; */
}

.x7-1 {
    margin-top: 48px;
    width: 1px;
    height: 174px;
    left: 0px;
    object-fit: cover;
    position: absolute;
    width: 280px;
}

.textalign-left{
    text-align: left;
    padding: 20px 26px;
}

.ellipse-13 {
  align-self: center;
  background-color: 
#e8f4ff;
  border-radius: 130px;
  height: 260px;
  position: relative;
  width: 260px;
}

.frame-65 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 212px;
  min-height: 454px;
  padding: 10px 0;
  width: 604px;
}

.resolving-debt-legal-action {
  height: 46px;
  letter-spacing: 0.36px;
  line-height: 46px;
  margin-left: 10px;
  white-space: nowrap;
}

.it-is-time-to-start {
  align-self: center;
  letter-spacing: 0.2px;
  line-height: 32px;
  margin-top: 36px;
  min-height: 352px;
  width: 584px;
}

.overlap-group-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 2px;
  margin-top: 130px;
  min-height: 1024px;
  width: 1922px;
}

.overlap-group2 {
  height: 550px;
  margin-right: 2px;
  position: relative;
  width: 1692px;
}

.colored-dots {
  height: 351px;
  left: 0;
  position: absolute;
  top: 131px;
  width: 1692px;
}

.group-19 {
  align-items: flex-end;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  left: 260px;
  min-height: 550px;
  padding: 122px 112.6px;
  position: absolute;
  top: 0;
  width: 1170px;
}

.frame-45 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  min-height: 196px;
  width: 326px;
}

.ready-to-take-action {
  letter-spacing: 0;
  line-height: 60px;
  min-height: 120px;
  width: 326px;
}

.dont-try-self-help-c {
  letter-spacing: 0.18px;
  line-height: 32px;
  margin-top: 12px;
  min-height: 64px;
  width: 326px;
}

.frame-46 {
  align-items: center;
  background-color: var(--azure-radiance);
  border-radius: 8px;
  display: flex;
  height: 48px;
  margin-right: 201px;
  margin-top: 40px;
  min-width: 125px;
  padding: 0 24px;
}

.start-now {
  letter-spacing: 0;
  min-height: 24px;
  min-width: 77px;
  text-align: center;
}

.footer-container {
  height: 474px;
  position: relative;
  width: 1922px;
}

.footer-bg {
  background-color: 
#241729;
  height: 474px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1920px;
}

.footer-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 367px;
  position: absolute;
  top: 70px;
  width: 1922px;
}

.flex-row-4 {
  align-items: flex-start;
  display: flex;
  height: 264px;
  margin-left: 1.06px;
  min-width: 1157px;
}

.flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 244px;
  width: 384px;
}

.image-2 {
  height: 84px;
  object-fit: cover;
  width: 211px;
}

.for-over-25-years-ap {
  letter-spacing: 0.14px;
  line-height: 28px;
  margin-left: 2px;
  margin-top: 20px;
  min-height: 140px;
  width: 382px;
}

.frame-59 {
  align-items: flex-start;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  margin-left: 218px;
  min-height: 244px;
  width: 298px;
}

.contact-us-1,
.follow-us {
  letter-spacing: 0;
  min-height: 36px;
}

.line-3 {
  height: 4px;
  margin-top: 24px;
  width: 40px;
}

.frame-58 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  min-height: 160px;
  width: 298px;
}

.frame-53 {
  align-items: center;
  display: flex;
  min-width: 294px;
}

.icon {
  height: 28px;
  width: 28px;
}

.phone {
  height: 26px;
  letter-spacing: 0.16px;
  margin-left: 20px;
  width: 246px;
}

.frame-54 {
  align-items: center;
  display: flex;
  margin-top: 24px;
  min-width: 282px;
}

.info-aprils-credit-repaircom {
  height: 24px;
  letter-spacing: 0.16px;
  margin-left: 20px;
  min-width: 234px;
}

.frame-55 {
  align-items: flex-start;
  display: flex;
  margin-top: 24px;
  min-width: 298px;
}

.address {
  height: 56px;
  letter-spacing: 0.16px;
  line-height: 28px;
  margin-left: 20px;
  width: 250px;
}

.frame-57 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 102px;
  margin-top: 20px;
  min-height: 122px;
  width: 155px;
}

.frame-52 {
  height: 38px;
  margin-left: 0;
  margin-top: 20px;
  width: 155px;
}

.flex-col-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 2px;
  margin-top: 60px;
  min-height: 43px;
  width: 1920px;
}

.line-2 {
  height: 1px;
  width: 1920px;
}

.frame-60 {
  align-items: flex-start;
  display: flex;
  height: 28px;
  margin-left: 20px;
  margin-top: 14px;
  min-width: 1168px;
}

.x2022-aprils-credit {
  letter-spacing: 0.12px;
  line-height: 28px;
  min-height: 28px;
  white-space: nowrap;
  width: 292px;
}

.website-designed-by-plaxonic {
  letter-spacing: 0.12px;
  line-height: 28px;
  margin-left: 439px;
  min-height: 28px;
  text-align: right;
  white-space: nowrap;
  width: 437px;
}

.span1 {
  color: 
#bababa;
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxs);
  font-weight: 400;
}

/* dots---- */


.dotone{
    position: absolute;
    width: 8px;
    height: 8px;
    left: -2%;
    bottom: 20%;
    border-radius: 50%;
    background: #6EBECE;

}

.dottwo{
  position: absolute;
  width: 15px;
  height: 15px;
  right: 10%;
  top: 12%;
  border-radius: 50%;
  background: #FF5775;
}

.dotthree{
    position: absolute;
    width: 7px;
    height: 7px;
    left: -5%;
    right: 0%;
    top: 94%;
    border-radius: 50%;
    background: #ff5775;
}



.dotfour{
    position: absolute;
    width: 15px;
    height: 15px;
    /* left: 0; */
    top: 92%;
    right: 0%;
    border-radius: 50%;
    background: #A9D8FF;
}

.dotfive{
 
    position: absolute;
    width: 5px;
    height: 5px;
    /* left: 0; */
    top: 79%;
    /* left: -20%; */
    right: 0%;
    border-radius: 50%;
    background: #BA0CDC;
}

/* Media Queries */

@media (Max-width: 580px) {



  .ellipse-12 {
    background-color: #dfd9ff;
    border-radius: 130px;
    height: 260px;
    left: 25px;
    position: absolute;
    top: 0;
    width: 260px;
}

  .overlap-group {
    height: 277px;
    /* margin-left: 146px; */
    margin-top: 10px;
    position: relative;
    width: 335px;
    margin: auto;
}

.overlap-group6 {
  height: 260px;
  /* margin-left: 139px; */
  position: relative;
  width: 315px;
  margin: auto;
}
  .coltextlitigation {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #747474;
}
.coliimage-litigation {
  width: 33rem !important;
  margin: left;
  top: 0px;
  text-align: left;
  height: 30rem;
  position: relative;
}
.imagelitigation-banner img {
  margin-top: -6rem;
  width: 100%;
  height: auto;
}
.poppins-medium-heavy-metal-36px {
  color: var(--heavy-metal);
  font-family: var(--font-family-poppins);
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
}
.poppins-normal-sonic-silver-20px {
  color: var(--sonic-silver);
  font-family: var(--font-family-poppins);
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
}

.flex-row {
  align-items: flex-start;
  display: block;
  height: auto;
  margin-right: 0px; 
   margin-top: 0px; 
   min-width: 1px;
}
.flex-row-1 {
  align-items: center;
  display: block;
  height: auto;
  margin-left: 0px;
  margin-top: 0px;
  min-width: 10px;
}
.overlap-group1 {
  height: 350px;
  position: relative;
  width: 295px;
  margin: auto;
}
.frame-62 {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  min-height: 1px;
  /* padding: 10px 10px; */
  width: fit-content;
  position: relative;
  
}

.frame-63{
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  min-height: 1px;
  /* padding: 10px 10px; */
  width: fit-content;
  position: relative;
  
 }
 .frame-64{
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  min-height: 1px;
  /* padding: 10px 10px; */
  width: fit-content;
  position: relative;
 
 }
 .flex-row-2 {
  align-items: center;
  display: block;
  height: 388px;
  margin-right: 1px;
  margin-top: 179px;
  min-width: 1px;
  margin-top: 0px;
}

.frame-65{
  
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  min-height: 1px;
  /* padding: 10px 10px; */
  width: fit-content;
  position: relative;
  
 }
 .flex-row-3 {
  align-items: flex-start;
  display: block;
  height: 454px;

  margin-top: 200px;
}
.ellipse-13 {
  align-self: center;
  background-color: #e8f4ff;
  border-radius: 130px;
  height: 260px;
  margin: auto;
  width: 260px;
  position: relative;
}
.take-action-litigation {
  position: relative;
  position: relative;
  display: flex;
  margin: auto;
  justify-content: center;
  /* padding: 30px 0px; */
  top: 12%;
  /* bottom: 200px; */
  margin-bottom: 0px;
}
.x7-1 {
  margin-top: 48px;
  width: 1px;
  height: 174px;
  left: 0px;
  object-fit: cover;
  position: absolute;
  width: 280px;

}
.imagelitigation {
  /* width: 100%; */
  height: auto;
  width: 320px;
  margin-top: 450px;
}



.taketestlit {
  position: absolute;
  width: 100px;
  right: 80px;
  color: black;
  left: 60%;
  text-align: left;
  top: 66%;
}
.taketestlit h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 8px;
  color: #2D2D2D;
  text-align: left;
}
.taketestlit p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.01em;
  color: #747474;
  text-align: left;
}
.taketestlit button {
  align-items: flex-start;
  padding: 0px 14px;
  /* gap: 10px; */
  width: 5.18rem;
  height: 27px;
  font-size: 10px;
  color: white;
  border: none;
  /* height: 48px; */
  background: #008AFF;
  border-radius: 4px;
}
}

@media only screen and (min-width: 581px) and (max-width: 785px){

  .taketestlit {
    position: absolute;
    width: 100px;
    right: 80px;
    color: black;
    left: 60%;
    text-align: left;
    top: 15%;
  }
  .taketestlit h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 8px;
    color: #2D2D2D;
    text-align: left;
  }
  .taketestlit p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.01em;
    color: #747474;
    text-align: left;
  }
  .taketestlit button {
    align-items: flex-start;
    padding: 0px 14px;
    /* gap: 10px; */
    width: 5.18rem;
    height: 27px;
    font-size: 10px;
    color: white;
    border: none;
    /* height: 48px; */
    background: #008AFF;
    border-radius: 4px;
  }

  .imagelitigation {
    /* width: 100%; */
    height: auto;
    width: 100%;
    position: relative;
}
  .overlap-group6 {
    height: 260px;
    /* margin-left: 139px; */
    position: relative;
    width: 315px;
    margin: auto;
}

  .overlap-group {
    height: 277px;
    /* margin-left: 146px; */
    margin-top: 10px;
    position: relative;
    width: 335px;
    margin: auto;
}
  .imagelitigation{
    width: 515px;
      height: auto;
  }
  .x7-1 {
    margin-top: 48px;
    width: 1px;
    height: 174px;
    left: 48px;
    object-fit: cover;
    position: absolute;
    width: 280px;
  }
  
  .take-action-litigation {
   
    display: flex;
    margin: auto;
    justify-content: center;
  
    top: 12%;
   
    margin-top: 253px;
    position: relative;
}

  .ellipse-13 {
    align-self: center;
    background-color: #e8f4ff;
    border-radius: 130px;
    height: 260px;
    
    width: 260px;
    margin: auto;
}
  .flex-row-3 {
    align-items: flex-start;
    display: block;
    height: 454px;
    
    margin-top: 250px;
}
  .flex-row-2 {
    align-items: center;
    display: block;
    height: 388px;
    margin-right: 1px;
    margin-top: 179px;
    min-width: 1px;
    margin-top: 0px;
}


.frame-65{
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  min-height: 1px;
  /* padding: 10px 100px; */
  width: 100%;
 }

  .frame-64{
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    min-height: 1px;
    /* padding: 10px 100px; */
    width: 100%;
   }
 .frame-63{
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  min-height: 1px;
  /* padding: 10px 100px; */
  width: 100%;
 }

  .frame-62 {
    align-items: center;
    display: flex;
    flex-direction: column;
    min-height: 332px;
    
    width: 100%;
}

.overlap-group1 {
  height: 350px;
  position: relative;
  width: 295px;
  margin: auto;
}
  .flex-row {
    align-items: flex-start;
    display: block;
    height: auto;
    margin-right: 0px; 
     margin-top: 0px; 
     min-width: 1px;
}
.flex-row-1 {
  align-items: center;
  display: block;
  height: auto;
  margin-left: 0px;
  margin-top: 0px;
  min-width: 10px;
}
  .poppins-normal-sonic-silver-20px {
    color: var(--sonic-silver);
    font-family: var(--font-family-poppins);
    font-size: 15px;
    font-weight: 400;
    font-style: normal;
}
  .coltextlitigation {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #747474;
}
.coliimage-litigation {
  width: 33rem !important;
  margin: left;
  top: 10px;
  text-align: left;
  height: 22rem;
  position: relative;
}
.imagelitigation-banner img {
 
    margin-top: 3rem;
    width: 100%;
    height: auto;

}
.poppins-medium-heavy-metal-36px {
  color: var(--heavy-metal);
  font-family: var(--font-family-poppins);
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
}
}

@media (min-width: 1600px) {

  .taketestlit button {
    align-items: flex-start;
    padding: 0px 14px;
    /* gap: 10px; */
    width: 5.18rem;
    height: 27px;
    font-size: 10px;
    color: white;
    border: none;
    /* height: 48px; */
    background: #008AFF;
    border-radius: 4px;
  }
  .x7-1 {
    margin-top: 48px;
    width: 1px;
    height: 174px;
    left: 0px;
    object-fit: cover;
    position: absolute;
    width: 280px;
}
}

@media only screen and (max-width: 575px){
  .restorelitigation {
    position: relative;
    display: block !important;
    /* display: flex; */
    margin: auto;
    width: 100% !important;
    top: 100px;
    height: auto;
    justify-content: center;
}


}


@media only screen and (min-width: 576px) and (max-width: 1279px){
  .restorelitigation {
    position: relative;
    display: block !important;
    /* display: flex; */
    margin: auto;
    width: 100% !important;
    /* top: 100px; */
    height: auto;
    justify-content: center;
}
.imagelitigation-banner img {
  margin-top: -5rem;
  width: 500px;
  height: 300px;
  margin-left: 28px;
}
.coliimage-litigation {
  width: 33rem !important;
  margin: left;
  top: 0px;
  text-align: left;
  height: 33rem;
  position: relative;
}

}

@media only screen and (min-width: 782px) and (max-width: 1279px){
  .flex-row {
    align-items: flex-start;
    display: block;
    height: 332px;
    margin-right:0px;
    margin-top: 0px;
    min-width: 750px;
}
.taketestlit {
  position: absolute;
  width: 210px;
  /* right: 160px; */
  color: black;
  text-align: left;
  top: 50%;
  right: 20%;
}

.imagelitigation {
  margin-bottom: 100px;
  width: 736px;
  margin-top: 300px;
}

.flex-row-1 {
  align-items: center;
  display: block;
  height: 498px;
  margin-top: 300px;
  margin-left: 100px;
  /* margin: auto; */
  min-width: 500px;
}

.frame-63 {
  align-items: center;
  display: block;
  flex-direction: column;
  margin-left: -50px;
  min-height: 498px;
  padding: 10px 0;
  width: 605px;
}
.flex-row-2 {
  align-items: center;
  display: block;
  height: 388px;
  margin-right: -33px;
  margin-top: 193px;
  min-width: 605px;
  margin-top: 200pxpx;
}
.ellipse-13 {
  align-self: center;
  background-color: #e8f4ff;
  border-radius: 130px;
  height: 260px;
  position: relative;
  width: 260px;
  margin: auto;
}
.flex-row-3 {
  align-items: flex-start;
  display: block;
  height: 454px;
  margin-left: 0px;
  margin-top: 301px;
}

.frame-62 {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 332px;
  padding: 10px 0;
  width: 605px;
  margin: auto;
}

.frame-65 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 0px;
  min-height: 454px;
  padding: 10px 0;
  width: 604px;
}

}


@media only screen and (min-width:1280px)  {
  .taketestlit h4 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 50px;
    color: #2D2D2D;
    text-align: left;
}

.taketestlit p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    width: 280px;
    margin-top: 20px;
    letter-spacing: 0.01em;
    color: #747474;
    text-align: left;
}

.taketestlit button {
    position: static;
    width: 7.18rem;
    height: 48px;
    left: 0px;
    /* top: 236px; */
    background: #008AFF;
    padding: 12px 25px;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    border: none;
    line-height: 24px;
    color: white;
}
.taketestlit{
  position: absolute;
  width: 30%;
  color: black;
  text-align: left;
  top: 22%;
  right: 5%;
}
}