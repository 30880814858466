@import url('https://fonts.googleapis.com/css2?family=Poppins');

.container-resources{
    background: linear-gradient(179.68deg, rgba(234, 228, 255, 0) -3.36%, rgba(237, 232, 255, 0.5) 98.75%);
    border-radius: 0px;
    height: auto;
}

.main {
    max-width: 850px !important;
    margin: auto;
    text-align: center;
    padding-top: 20px;
    display: block;
  
}


.main .headline {
    font-size: 55px;
    padding: 10px 5px 0 5px;
    font-weight: 500;
    font-family: 'Poppins';
    font-style: normal;

    line-height: 105px;
    margin-top: 100px;
    align-items: center;
    text-align: center;
    color: #000000;
}

.main .headline h1{
    font-size: 55px;
    padding: 10px 5px 0 5px;
    font-weight: 500;
    font-family: 'Poppins';
    font-style: normal;

    line-height: 105px;
    margin-top: 0px;
    align-items: center;
    text-align: center;
    color: #000000;
}
.main .creditline {
    
    max-width: 715px;
    margin: auto;
  
    margin-bottom: 5px;
}

.creditline h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    display: block;
    align-items: center;
    text-align: center;
    margin: auto;
    padding-top: 30px;
}


.main .werds {
    padding: 35px 0;
    font-size: 17px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
 
    line-height: 27px;
    text-align: center;
    color: #000000;
}

.main .next {
    max-width: 800px;
    margin: auto;
    text-align: center;
    padding: 20px 0;
    font-weight: 700;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    width: 525px;
    line-height: 27px;
    text-align: center;
}

.main .credit {
    border-bottom: 2px #CFCFCF solid;
    max-width: 700px;
    margin: auto;
    padding: 5px 5px 35px 5px;
    display: flex;
flex-direction: row;
align-items: flex-start;
padding: 12px 24px;
gap: 10px;

width: 490px;
height: 57px;

background: #008AFF;
border-radius: 8px;
}

.credit button{
    background-color: #008aff;
    border: none;
    color: white;
    margin: auto;
    width: 460px;
}
.credit a{
  text-decoration: none;
}

.credit{
   
    padding: 10px 80px;
}
.box {
    background:  #FFFFFF;
    border-bottom: 2px #3E89BF solid;
    margin-bottom: 20px;
    width: 70% !important;
    margin: auto;
}

.form button{
    background-color: #008AFF;
    font-size: 14px;
    border: none;
    padding: 10px;
    color: white;
    border-radius: 8px;
}

.box .left {
    float: left;
    width: 20%;
}

.box .icon img {
    float: left;
}

.box .middle {
    float: left;
    width: 55%;
    text-align: left;
}

.box .title-res {
    font-size: 18px;
    font-weight: 600;
    
}
.box .werds {
    font-size: 13px;
    line-height: 1.6;
}
.box .right {
    float: right;
    width: 25%;
    text-align: center;
    margin-top: 15px;
}
.box .logo {
    padding: 15px 0;
}
.box .note {
    font-size: 9px;
}
.clear {
    clear: both;
}
.blank{
    margin-top: 100px !important;
}
.dotoneres{
    position: absolute;
    width: 8px;
    height: 8px;
    left: 10%;
    bottom: 6%;
    border-radius: 50%;
    background: #6EBECE;
    top: 55%;
}
.dottwores{
    position: absolute;
    width: 15px;
    height: 15px;
    right: 10%;
    top: 85%;
    border-radius: 50%;
    background: #FF99D0;
}
.dotthreeres{
    position: absolute;
    width: 7px;
    height: 7px;
    left: 90%;
    right: 0%;
    top: 50%;
    border-radius: 50%;
    background: #ff5775;
}
.dotfourres{
    position: absolute;
    width: 15px;
    height: 15px;
    /* left: 0; */
    top: 69%;
    right: 5%;
    border-radius: 50%;
    background: #A9D8FF;

}
.dotfiveres{
    position: absolute;
    width: 5px;
    height: 5px;
    /* left: 0; */
    top: 88%;
    left: 2%;
    /* right: 0%; */
    border-radius: 50%;
    background: #BA0CDC;
}

.creditline ul {
  list-style:none;
  width:100%;
  font-size:0;
}

.creditline ul li {
  display:inline-block;
  width:20%;
  height:7px;
}

.creditline ul li:nth-child(1) {
  background:#2ecc71;
}

.creditline ul li:nth-child(2) {
  background:#3498db;
}

.creditline ul li:nth-child(3) {
  background:#f1c40f;
}

.creditline ul li:nth-child(4) {
  background:#e74c3c;
}

.creditline ul li:nth-child(5) {
  background:#9b59b6;
}




@media screen and (max-width: 655px){
    .box .left{
        display: none;
    }
    .box .middle{
        width:65%;
        padding-left:5px;
        box-sizing: border-box;
    }
    .box .right{
        width:35%;
    }
    .main .werds{
        font-size:14px;
    }

 
}

@media screen and(max-width: 1299px) {
    .main .credit {
        border-bottom: 2px #CFCFCF solid;
        max-width: 700px;
        margin: auto;
        padding: 5px 5px 35px 5px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 24px;
        gap: 10px;
        width: fit-content;
        height: 57px;
        background: #008AFF;
        border-radius: 8px;
    }
    
}

@media screen and (max-width: 500px){

    .creditline ul {
        list-style: none;
        width: 90%;
        font-size: 0px;
    }
    .creditline h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 48px;
        display: block;
        align-items: center;
        text-align: center;
        margin: auto;
        padding-top: 30px;
    }
    .box .left{
        display: none;
    }
    .box .middle{
        width:100%;
        padding-left:5px;
        box-sizing: border-box;
        float:initial;
    }
    .box .right{
        width:100%;
        float:initial;
    }
    .box .title-res {
        font-size: 15px;
        font-weight: 600;
    }
    .title-res {
        letter-spacing: 0;
        line-height: 30px;
        width: fit-content;
    }

    .main .next {
        max-width: 800px;
        margin: auto;
        text-align: center;
        padding: 20px 0;
        font-weight: 700;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        width: fit-content;
        line-height: 22px;
        text-align: center;
        font-size: 13px;
    }
    .credit button {
        background-color: #008aff;
        border: none;
        color: white;
        margin: auto;
       
        width: fit-content;
        font-size: 12px;
    }

    .main .credit {
        border-bottom: 2px #CFCFCF solid;
        max-width: 700px;
        margin: auto;
        padding: 5px 5px 35px 5px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 24px;
        gap: 10px;
        width: 366px;
        height: auto;
        background: #008AFF;
        border-radius: 8px;
    }

 
}

@media screen and (min-width:501px) and (max-width:768px){
    .box .icon img {
        float: left;
      visibility: hidden;
    }

    .box .left {
        float: left;
        width: 0%;
    }
    .credit button {
        background-color: #008aff;
        border: none;
        color: white;
        margin: auto;
        width: 380px;
        font-size: 14px;
    }
    .main .credit {
        border-bottom: 2px #CFCFCF solid;
        max-width: 700px;
        margin: auto;
        padding: 5px 5px 35px 5px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 24px;
        gap: 10px;
        width: 430px;
        height: 57px;
        background: #008AFF;
        border-radius: 8px;
    }
  
}

@media screen and (min-width:1250px){
    .main .credit {
        border-bottom: 0px #CFCFCF solid;
        max-width: 700px;
        margin: auto;
        padding: 5px 5px 35px 5px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 24px;
        gap: 10px;
        width: 500px;
        height: 57px;
        background: #008AFF;
        border-radius: 8px;
    }
}
