:root {
    --black: 
  rgba(0, 0, 0, 1);
    --azure-radiance: 
  rgba(0, 138, 255, 1);
    --shark: 
  rgba(41, 42, 44, 1);
    --heavy-metal: 
  rgba(45, 45, 45, 1);
    --sonic-silver: 
  rgba(115, 115, 115, 1);
    --sonic-silver-2: 
  rgba(117, 117, 117, 1);
    --mobster: 
  rgba(127, 114, 133, 1);
    --quick-silver: 
  rgba(165, 165, 165, 1);
    --silver-chalice: 
  rgba(171, 171, 171, 1);
    --white: 
  rgba(255, 255, 255, 1);
  
    --font-size-xxxs: 12px;
    --font-size-xxs: 14px;
    --font-size-xs: 14px;
    --font-size-s: 16px;
    --font-size-m: 18px;
    --font-size-l: 36px;
    --font-size-xl: 70px;
  
    --font-family-poppins: "Poppins";
  }
  
  
  
  .poppins-medium-white-16px {
    color: var(--white);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xs);
    font-weight: 500;
    font-style: normal;
  }
  .poppins-medium-heavy-metal-70px {
    color: var(--heavy-metal);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xl);
    font-weight: 500;
    font-style: normal;
  }
  .poppins-medium-silver-chalice-16px {
    color: var(--silver-chalice);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xs);
    font-weight: 500;
    font-style: normal;
  }
  .poppins-normal-white-18px {
    color: var(--white);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
  .poppins-normal-mobster-14px {
    color: var(--mobster);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xxs);
    font-weight: 400;
    font-style: normal;
  }
  .poppins-medium-heavy-metal-36px {
    color: var(--heavy-metal);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-l);
    font-weight: 500;
    font-style: normal;
  }
  .poppins-normal-mobster-12px {
    color: var(--mobster);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xxxs);
    font-weight: 400;
    font-style: normal;
  }
  .poppins-normal-sonic-silver-20px-2 {
    color: var(--sonic-silver-2);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
  .poppins-medium-shark-16px {
    color: var(--shark);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xs);
    font-weight: 500;
    font-style: normal;
  }
  .poppins-semi-bold-heavy-metal-70px {
    color: var(--heavy-metal);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xl);
    font-weight: 600;
    font-style: normal;
  }
  .poppins-normal-sonic-silver-18px {
    color: var(--sonic-silver);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-s);
    font-weight: 400;
    font-style: normal;
  }
  .poppins-medium-quick-silver-16px {
    color: var(--quick-silver);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xs);
    font-weight: 500;
    font-style: normal;
  }
  .poppins-normal-white-16px {
    color: var(--white);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-xs);
    font-weight: 400;
    font-style: normal;
  }
  .poppins-normal-heavy-metal-48px {
    color: var(--heavy-metal);
    font-family: var(--font-family-poppins);
    font-size: 48px;
    font-weight: 400;
    font-style: normal;
  }
  .poppins-normal-sonic-silver-20px {
    color: var(--sonic-silver);
    font-family: var(--font-family-poppins);
    font-size: var(--font-size-m);
    font-weight: 400;
    font-style: normal;
  }
  .poppins-medium-white-24px {
    color: var(--white);
    font-family: var(--font-family-poppins);
    font-size: 24px;
    font-weight: 500;
    font-style: normal;
  }