@import url('https://fonts.googleapis.com/css2?family=Poppins');


/* slider */

.designedby a {
    text-decoration: none;
}
.btn:focus {
    outline: none;
    box-shadow: none;
  }

.faqspagefull{
   
    width: 100%;
    position: absolute;
    height: auto;
     border: none;
    width: 100%;
    position: absolute;
    height: auto;
    /* display: block; */
    left: 0px;
    margin: 0;

    line-height: 1.6;
    /* padding: 30px 0; */
    
    padding: 15px 0;
    font-size: 30px;
    font-weight: 300;
   background-color: #A924E0;;
    text-transform: none;
    border-radius: 0px;
    text-shadow: 1px 1px 1px rgb(0 0 0 / 10%);
    cursor: pointer;
}

.Ccontainer {
    float: top;
    display: flex;
    flex-direction: column;
    margin-left: 110px;
    margin-top: 245px;
  }

.Ccontainer button{
    color: #A5A5A5;
    background: transparent;
    border: none;
    
    margin-bottom: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
  .main {
    display: flex;
  }
  
  .five img {
    margin-top: 5px;
    transition: transform 1s;
  }
  
  .six img {
    margin-top: 5px;
    transition: transform 1s
  }
  .seven img {
    margin-top: 5px;
    transition: transform 1s;
  }

  .eight img{
    margin-top: 5px;
    transition: transform 1s;
  }
  
  .five {
    width: 570px;
    height: 685px;
    background-color: #DDD4FF;
    border-radius: 16px;
  }
  
  .six {
    width: 570px;
    height: 685px;
    background-color:  #BCE0FF;
    position: relative;
    border-radius: 16px;
  }
  
  .seven {
    width: 570px;
    height: 685px;
    background-color: #FDDFE9;
    position: relative;
    border-radius: 16px;
  }

  .eight{
    width: 570px;
    height: 685px;
    background-color: #F5E0FE;
    position: relative;
    border-radius: 16px;
  }
  
.faqspagefull:hover{
    background-color: #0dcaf0;
    transition: 0.3s;
}

/*  */

.restore-main-container{
    position: relative;
    display: inline-block;
    margin: auto;
    width: 1250px;
    height: auto;
    top: 80px;
    /* border: 1px solid black; */
   
    justify-content: center;
    padding: 2.5rem 2rem;
}

.restore-text{
    padding: 3rem 0rem;
    width: 100%;
    
    text-align: left;
}

.cardhome{
    width: 100%;
    height: 26rem;
    display: flex;
    /* background: #EDE8FF; */
    border-radius: 16px;
}


.text-centerhome{
 
    line-height: 40px;
    top: 35%;
}

.text-centerhome p span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
}
.text-centerhome p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 45px;
    line-height: 45px;
}
.details p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0.01em;
    color: #747474;
    flex: none;
    order: 1;
    flex-grow: 0;
}

.btn-consulation{
    padding: 1rem 0rem;
}


.btn-consulation button{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  
    position: static;
 
    color: white;
  
    background: #A924E0;
    border-radius: 5px;
    border: none;
    padding: 16px 32px;
    letter-spacing: 0.5px;
    font-size: 10px;
}


.cardhome img{
    transition: transform 1s;
    position: relative;
    width: 100%;
    top: 20%;
    left: -15px;
    /* transition: display 0.2s; */

}

/* .cardhome img:hover {
    transform: matrix(-1, 0, 0, 1, 0, 0);
  } */

  
/* Services styles */

.services-container{
    position: relative;
    display: block;
    margin: auto;
    
    height: auto;
    /* border: 1px solid black; */
    justify-content: center;
    padding: 2.5rem 2rem;
}

.services-head{
 
    text-align: left;
    margin-bottom: 70px;
}

.services-head h6{
    font-family: 'Poppins';
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
  
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #A924E0;
    position: relative;
    
}

.services-head h4{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    color: #2D2D2D;
}

.services-head h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    color: #2D2D2D;
}

.services-head p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #747474;

}

.img-services{
    position: relative;
    width: 30px;
    height: 30px;
    top: 10%;
}

.services{
   
    position: relative;
    flex-wrap: wrap;
    flex-wrap: wrap;
    display: flex;
    margin: auto;
    width: 70%;
    width: 100% !important;
    height: auto;
 
    justify-content: space-between;
    /* border: 1px solid black; */
    padding: 0 2rem;
}


    .titleservices {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        width: 200px;
        font-size: 12px;
        line-height: 36px;
        color: #2A2A2D;
        text-align: left;
    }



    .description-services {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        width: fit-content;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #747474;
        text-align: left;
    }

.render-container{
    height: auto;
   
}
.render{
    width: 40px;
    height: 40px;
   
    border-radius:7px;
    position: relative 
}
.btn-services{
    text-align: start;
    padding: 2.5rem 0;
    position: relative;
}
.btn-services button{
    position: static;
    width: fit-content;
    height: 30px;
    left: 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 9px;
    line-height: 24px;
    color: #7A7A7A;
    top: 255px;
    background: #FFFFFF;
    border: 1px solid #7A7A7A;
    border-radius: 4px;
    transition: all 0.5s;
    cursor: pointer;
}
.btn-services button:hover{
    border-color: #008AFF;
    color: #008AFF;
}

/* Take action image  */

.take-action img{
    position: relative;
    width: 100%;
}

.taketest{
    position: absolute;
    width: 210px;
    /* right: 160px; */
    color: black;
    text-align: left;
    top: 25%;
    right: 5%;
}



.taketest button{
    position: static;
    width: 70px;
    /* height: 48px; */
    left: 0px;
    /* top: 236px; */
    background: #008AFF;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    border: none;
    line-height: 24px;
    color: white;
    
    }
    


.take-action{
    position: relative;
    position: relative;
    display: flex;
    margin: auto;
    justify-content: center;
    padding: 30px 0px;
    
}


.taketest h4{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 30px;
    color: #2D2D2D;
    text-align: left;
}

.taketest p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #747474;
    text-align: left;
}

a:hover {     text-decoration: none; }

NavLink{
    text-decoration: none;
}

/* FAQ */

.faq-container{
    position: relative;
   
    margin: auto;
    width: 70%;
    height: auto;
    display: inline-flex;
    justify-content: center;
    padding: 2.5rem 2rem;
    margin-bottom: 65px;
}

.faq-question h6{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 10px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 0.01em;
}

.faq-answer p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    white-space: pre-wrap;
    line-height: 20px;
    display: block;
    align-items: flex-end;
    letter-spacing: 0.01em;
    text-align: left;
    color: #2D2D2D;
}

.faqs {
    position: relative;
    display: inline-block;
}

/* .faqs-head{
    height: auto;
    position: relative;
    width: 300px !important;
    text-align: left;
} */


.faqs-head h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 22px;
    color: #2D2D2D;
}

.faqs-head p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: #747474;
}

.faqs-head h6{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 27px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #A924E0;


}

.faqs-head button{
    align-items: flex-start;
    padding: 6px 8px;
    /* gap: 10px; */
    font-size: 10px;
    width: 60px;
    color: white;
    border: none;
    /* height: 48px; */
    background: #008AFF;
    border-radius: 4px;
}

.faqs .faq {
  
    text-align: left;
    width: 525px;
    /* height: 30px; */
    padding: 0.5rem 1rem;
    margin: 10px;
    background-color: #F5F5F5;
    border-radius: 2px;
    position: relative;
}

.faqs .faq .faq-question {
	position: relative;
	font-size: 20px;
	padding-right: 80px;
    margin-top: 5px;
	transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%);
	width: 10px;
	height: 30px;
    background-size: 50px 50px;
	background-image: url('../assets//images/Vector.png');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
	opacity: 0;
	max-height: 0;
	overflow-y: hidden;
	transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
	margin-bottom: 0px;
}

.faqs .faq.open .faq-question::after {
	transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
	max-height: 1000px;
	opacity: 1;
}


.dotone{
    position: absolute;
    width: 8px;
    height: 8px;
    left: -10%;
    bottom: 60%;
    border-radius: 50%;
    background: #6EBECE;

}

.dottwo{
  
    position: absolute;
    width: 15px;
    height: 15px;
    right: 10%;
    top: 12%;
    border-radius: 50%;
    background: #FF5775;
}

.dotthree{
    position: absolute;
    width: 7px;
    height: 7px;
    /* left: 50%; */
    right: 0%;
    /* top: 10%; */
    border-radius: 50%;
    background: #ff5775;

}

.btn-consulation a{
    text-decoration: none;
}

.dotfour{
    position: absolute;
    width: 15px;
    height: 15px;
    /* left: 0; */
    top: 52%;
    right: -10%;
    border-radius: 50%;
    background: #A9D8FF;
}

.dotfive{
    position: absolute;
    width: 5px;
    height: 5px;
    /* left: 0; */
    top: 88%;
    left: -20%;
    /* right: 0%; */
    border-radius: 50%;
    background: #BA0CDC;
    ;
}






/* Footer */

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,500,300,700);


section {
  width: 100%;
  display: inline-block;
  background: #333;
  height: 50vh;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  text-decoration: underline;
}

.footer-distributed{
	background: #241729;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
	box-sizing: border-box;
	

    max-width: 100% !important;
	font: bold 16px sans-serif;
	padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right{
	display: inline-block;
	vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left{
	
    padding: 0rem 1rem;
}

.footer-left{
    width: 100%;
    text-align: left;
    left: 35%;
    position: relative;
}



.footer-links{
    position: relative;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.01em;
    top: 10px;
    color: #807286;
}
/* The company logo */

.footer-distributed h3{
	color:  #ffffff;
	font: normal 36px 'Open Sans', cursive;
	margin: 0;
}

.footer-distributed h3 span{
	color:  lightseagreen;
}

/* Footer links */





.footer-distributed .footer-company-name{
	color:  #222;
	font-size: 14px;
	font-weight: normal;
	margin: 0;
}

/* Footer Center */

.footer-center{
    width: 50%;
    text-align: left;
    left: 20%;
    margin: auto;
    position: relative;
}



.footer-distributed .footer-center p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin: 5px 5px;
    
}

.callnum{
    margin-top: 30px;
}

.call img{
    width: 10px;
    margin: 8px;
}

.footer-distributed .footer-center span{
	display: block;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 36px;
    color: #FFFFFF;
    /* font-weight: normal; */
    /* font-size: 14px; */
    line-height: 2;
}

.footer-distributed .footer-center p a{
	color:  lightseagreen;
	text-decoration: none;;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight:300;
  font-size: 20px;
  left: 0;
  color: #fff;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */


.footer-distributed .footer-company-about{
    line-height: 20px;
    color: #92999f;
    font-size: 13px;
    font-weight: normal;
    margin: 0;
    position: relative;
}

.footer-distributed .footer-company-about span{
	display: block;
    color: #ffffff;
    font-size: 13px;
    font-weight: bold;
    margin: 12px 5px;
}



.footer-icons img{
    width: 30px;
}

.footer-distributed .footer-icons a{
	display: inline-block;
	width: 35px;
	height: 35px;
	cursor: pointer;

	border-radius: 2px;

	font-size: 20px;
	color: #ffffff;
	text-align: center;
	line-height: 35px;

	margin-right: 3px;
	margin-bottom: 5px;
}

.footer-right{
    padding-right: 20%;
    text-align: left;
    position: relative;
    top: 4%;

}
/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {

    .navigation-menu li a {
        text-decoration: none;
        display: block;
        width: 100%;
        color: #A5A5A5;
        position: relative;
        font-size: 15px;
    }

	.footer-distributed{
		font: bold 14px sans-serif;
	}

	.footer-distributed .footer-left,
	.footer-distributed .footer-center,
	.footer-distributed .footer-right{
		display: block;
		width: 100%;
		margin-bottom: 40px;
		text-align: center;
	}

	.footer-distributed .footer-center i{
		margin-left: 0;
	}

}

.rowcol{
    max-width: 100% !important;
    height: auto;
    background-color:#241729;
;
}

.borderlast{
    border-bottom: 1px solid #474149;
    padding: 50px 0px;
}

.lastcontent{
    display: flex;
    justify-content: space-around;
    color: #807286;
    position: relative;
    top: 20px;

}

.designedby small{
    font-size: 12px;
    color: ghostwhite;

}
.lastcontent.copywrite{
    text-align: left;
}
.copywrite{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 28px;
 
    letter-spacing: 0.01em;
    color: #807286;
}
.designedby {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    /* padding: 0px 148px; */
    line-height: 28px;
    /* text-align: right; */
    letter-spacing: 0.01em;
    color: #807286;
}

.designedby span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
 
    line-height: 28px;
    text-align: right;
    letter-spacing: 0.01em;
    color: darkgrey;
}

.btn-services button{
    cursor: pointer;
    display: inline-block;
   
    transition: 0.5s;
}

/* .btn-services button::after{
    content: '»';
    position: absolute;
    opacity: 0;  
    top: 45px;
    left: -20px;
    transition: 0.5s;
    color: #008AFF;
} */

.btn-services button:hover{
  
    border-color: #008AFF;
    color: #008AFF;
}

.btn-services button:hover::after{
    opacity: 1;
    right: 10px;
}

/* Media Queries -------*/

@media only screen and (min-width: 480px) and (max-width: 1279px){
  

    .Ccontainer {
        float: top;
        display: flex;
        flex-direction: column;
        margin-left: 5px;
        margin-top: 155px;
      }
    .Ccontainer button{
        color: #A5A5A5;
        background: transparent;
        border: none;
        
        margin-bottom: 30px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    .five img {
        margin-top: 50px;
        transition: transform 1s;
      }
      
      .six img {
        margin-top: 50px;
        transition: transform 1s
      }
      .seven img {
        margin-top: 50px;
        transition: transform 1s;
      }
    
      .eight img{
        margin-top: 50px;
        transition: transform 1s;
      }
      
      .five {
        width: 470px;
        height: 445px;
        background-color: #DDD4FF;
        border-radius: 16px;
      }
      
      .six {
        width: 470px;
        height: 445px;
        background-color:  #BCE0FF;
        position: relative;
        border-radius: 16px;
      }
      
      .seven {
        width: 470px;
        height: 445px;
        background-color: #FDDFE9;
        position: relative;
        border-radius: 16px;
      }
    
      .eight{
        width: 470px;
        height: 445px;
        background-color: #F5E0FE;
        position: relative;
        border-radius: 16px;
      }

      .cardhome{
        width: 100%;
        height: 16rem;
        display: flex;
        /* background: #EDE8FF; */
        border-radius: 16px;
    }
  
  
  
    .cardhome img {
        transition: transform .7s ease-in-out;
        position: relative;
        width: 100%;
        top: 20%;
        /* left: -15px; */
        margin: auto;
    }
  .footer-left {
    width: 75%;
    text-align: left;
    left: 0%;
    
}

.lastcontent {
    display: block;
    justify-content: space-around;
    padding: 10px 0px;
    color: #807286;
    margin: auto;
}
.footer-distributed .footer-center p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin: 5px 5px;
    justify-content: center;
}
.footer-center {
    width: 50%;
    text-align: left;
    left: 0%;
    margin: auto;
    position: relative;
}
.footer-right {
    padding-right: 0%;
    
}
.footer-links {
    position: relative;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    letter-spacing: 0.01em;
    top: 10px;
    color: #807286;
    /* text-align: center; */
    width: 50%;
    margin: auto;
}
}
  
  @media only screen and (min-width: 480px) and (max-width: 1279px){
      .restore-main-container{
          position: relative;
          display: block;
          margin: auto;
          width: 90%;
         
          /* border: 1px solid black; */
          top: 50px;
          justify-content: center;
          padding: 2.5rem 2rem;
      }
      .restore-text {
          width: 100%;
          height: auto;
          /* border: 1px solid black; */
          text-align: left;
      }
      .cardhome {
          width: 100%;
          height: 450px;
          /* border: 1px solid black; */
          /* background: #EDE8FF; */
          border-radius: 16px;
      }
  }
  
  @media screen and (max-width: 480px) {

    .navhome a{
        width: 100%;
        height: auto;
    }
    .services-head p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.01em;
        color: #747474;
    }

    .description-services {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        width: -moz-fit-content;
        width: fit-content;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.01em;
        color: #747474;
        text-align: left;
    }

    .titleservices {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        width: 200px;
        font-size: 16px;
        line-height: 36px;
        color: #2A2A2D;
        text-align: left;
    }

    .btn-services button {
        position: static;
        width: -moz-fit-content;
        width: fit-content;
        height: 30px;
        left: 0px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #7A7A7A;
        top: 255px;
        background: #FFFFFF;
        border: 1px solid #7A7A7A;
        border-radius: 4px;
        transition: all 0.5s;
        cursor: pointer;
    }

    .lastcontent {
        display: block;
        justify-content: space-around;
        padding: 10px 0px;
        color: #807286;
        margin: auto;
    }
      .restore-main-container{
          position: relative;
          display: block;
          margin: auto;
          width: 100%;
         
          height: auto;
          /* border: 1px solid black; */
          top: 0px;
          justify-content: center;
          padding: 2.5rem 2rem;
      }

      .cardhome img {
       
            transition: transform .7s ease-in-out;
            position: relative;
            width: 100%;
           top: 25%;
            /* left: -15px; */
            /* margin: auto; */
       
    }

    .five {
        width: 285px;
        height: 350px;
        background-color: #DDD4FF;
        border-radius: 16px;
    }

    .six {
        width: 285px;
        height: 350px;
        background-color:  #BCE0FF;
        position: relative;
        border-radius: 16px;
      }
      
      .seven {
        width: 285px;
        height: 350px;
        background-color: #FDDFE9;
        position: relative;
        border-radius: 16px;
      }
    
      .eight{
        width: 285px;
        height: 350px;
        background-color: #F5E0FE;
        position: relative;
        border-radius: 16px;
      }

      .services-head h6 {
        font-family: 'Poppins';
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 9px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #A924E0;
        position: relative;
        top: 0;
    }
      .services-container {
        
        position: relative;
        display: block;
       
        height: auto;
        justify-content: center;
        padding: 2.5rem 2rem;
        
       
       
    }

    .Ccontainer {
        
            float: top;
            display: flex;
            flex-direction: row;
           
            margin: auto;
            position: relative;
            top: 200px;
            right: 200px;
        
    }


    .faq-container {
        position: relative;
        margin: auto;
        width: 70%;
        height: auto;
        display: block;
        justify-content: center;
        /* padding: 2.5rem 2rem; */
        margin-bottom: 100px;
    }

    .copywrite {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        width: fit-content;
        line-height: 28px;
        /* padding: 0px 170px; */
        /* text-align: right; */
        letter-spacing: 0.01em;
        color: #807286;
    }
 

      .restore-text {
          width: 100%;
          height: auto;
          /* border: 1px solid black; */
          text-align: left;
      }

      .services-head h6 {
       
            font-family: 'Poppins';
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #A924E0;
            position: relative;
            /* top: 10%; */
        
     }

      .cardhome {
          width: 100%;
          height: 400px;
          /* border: 1px solid black; */
          /* background: #EDE8FF; */
          border-radius: 16px;
      }

      .footer-left {
        width: 75%;
        text-align: left;
        left: 0%;
        
    }
    
    .footer-distributed .footer-center p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        margin: 5px 5px;
        justify-content: center;
    }
    .footer-center {
        width: 50%;
        text-align: left;
        left: 0%;
        margin: auto;
        position: relative;
    }
    .footer-right {
        padding-right: 0%;
        
    }
    .footer-links {
        position: relative;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.01em;
        top: 10px;
        color: #807286;
        /* text-align: center; */
        width: 50%;
        margin: auto;
    }
}

     /* Services styles for media query*/ 

     @media screen and (min-width: 481px) and (max-width: 768px) {
        .description-services {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            width: 100%;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0.01em;
            color: #747474;
            text-align: left;
        }

        .titleservices {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            width: fit-content;
            font-size: 16px;
            line-height: 36px;
            color: #2A2A2D;
            text-align: left;
        }

        .btn-services button {
            position: static;
            width: -moz-fit-content;
            width: fit-content;
            height: 30px;
            left: 0px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #7A7A7A;
            top: 255px;
            background: #FFFFFF;
            border: 1px solid #7A7A7A;
            border-radius: 4px;
            transition: all 0.5s;
            cursor: pointer;
        }
     
        .Ccontainer {
            
            display: flex;
            flex-direction: column;
            margin-left: 5px;
            margin-top: 45px;
        }
        .services-head {
            text-align: left;
            padding: 3rem 3rem;
        }

        .faqs .faq {
            text-align: left;
            width: 100%;
            /* height: 30px; */
            padding: 0.5rem 0.5rem;
            margin: 10px 0px;
            background-color: #F5F5F5;
            border-radius: 2px;
            position: relative;
        }

        .five {
            width:100%;
            height: 420px;
            background-color: #DDD4FF;
            border-radius: 16px;

        }

        .six {
            width: 100%;
            height: 420px;
            background-color: #BCE0FF;
            position: relative;
            border-radius: 16px;
        }
        .seven {
            width: 100%;
            height: 420px;
            background-color: #FDDFE9;
            position: relative;
            border-radius: 16px;
        }
        .eight {
            width: 100%;
            height: 420px;
            background-color: #F5E0FE;
            position: relative;
            border-radius: 16px;
        }

        .Ccontainer {
            float: top;
            display: grid;
            flex-direction: row;
            margin-left: 40px;
            margin-top: 148px;
        }

        .btn-consulation button {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
          
            position: static;
            color: white;
            background: #A924E0;
            border-radius: 5px;
            border: none;
            letter-spacing: 0.5px;
            font-size: 15px;
            padding: 16px 32px;
        }

        .services-head h6 {
            font-family: 'Poppins';
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #A924E0;
            position: relative;
        }
        .services-head p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0.01em;
            color: #747474;
        }

        .details p {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 30px;
            letter-spacing: 0.01em;
            color: #747474;
            flex: none;
            order: 1;
            flex-grow: 0;
        }

        .faq-container {
            position: relative;
            display: inline-block;
            margin: auto;
            width: 70%;
            height: auto;
            display: inline-block;
            justify-content: center;
            /* padding: 2.5rem 2rem; */
            margin-bottom: 100px;
        }
        
        .services-head h4 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #2D2D2D;
            margin-top: 35px;
        }
        }
        
     @media only screen and (max-width: 600px) {
          

        
            .services {
                position: relative;
                flex-wrap: wrap;
                flex-wrap: wrap;
                display: block;
                margin: auto;
                width: 70%;
                height: auto;
                justify-content: space-evenly;
              
                padding: 0 2rem;
            }
        
            .render {
                width: 40px;
                height: 40px;
                
                border-radius: 7px;
                position: relative;
            }
        
            
            .render-container {
                height: auto;
                width: 100%;
               
            }

           
            .btn-services {
                text-align: start;
               
            }
            .render-container a {
                height: auto;
                width: 200px;
            }
        }
        

        @media only screen and (min-width: 600px) and (max-width: 780px) {

            .taketest button {
                position: static;
                width: 85px;
                /* height: 48px; */
                left: 0px;
                /* top: 236px; */
                background: #008AFF;
                border-radius: 4px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                border: none;
                line-height: 24px;
                color: white;
            }

            .faqs .faq {
                text-align: left;
                width: 100%;
                /* height: 30px; */
                padding: 0.5rem 0.5rem;
                margin: 10px 0px;
                background-color: #F5F5F5;
                border-radius: 2px;
                position: relative;
            }
            .services {
                
              
                position: relative;
                flex-wrap: wrap;
                flex-wrap: wrap;
                display: inline-block;
                margin: auto;
                width: 70%;
                height: auto;
                justify-content: space-evenly;
                
                padding: 0 2rem;
            }
            .render {
                width: 40px;
                height: 40px;
              
                border-radius: 7px;
                position: relative;
            }
        
            
            .render-container {
                height: auto;
                width: 100%;
              
            }
            .btn-services {
                text-align: start;
                
            }
            .take-action img {
                width: 100%;
                position: relative;
                margin: auto;
                top: 100%;
            }
        
            .taketest {
                position: absolute;
                width: 210px;
                right: 160px;
                color: black;
               
                left: 56%;
                text-align: left;
            }

            .render {
                width: 60px;
                height: 60px;
                border-radius: 7px;
                position: relative;
            }

            .faq-question h6 {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 25px;
                display: flex;
                align-items: flex-end;
                letter-spacing: 0.01em;
            }

            .img-services {
                position: relative;
                width: 50px;
                height: 50px;
                top: 10%;
            }

            .faq-answer p {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                white-space: pre-wrap;
                line-height: 25px;
                display: block;
                align-items: flex-end;
                letter-spacing: 0.01em;
                text-align: left;
                color: #2D2D2D;
            }
}



@media only screen and (min-width: 490px) and (max-width:600px) {
    .take-action {
        position: relative;
        display: block;
       
    }
    .take-action img{
        width: 100%;
        position: relative;
        top: 50%;
    }
    .taketest {
        position: absolute;
        width: 142px;
        color: black;
        top: 22%;
        right: 0;
        text-align: left;
    }
    .taketest h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #2D2D2D;
        text-align: left;
    }

    .taketest p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #747474;
        text-align: left;
    }

    .taketest button {
        align-items: flex-start;
        padding: 0px 14px;
        /* gap: 10px; */
        width: 7.18rem;
        height: 35px;
        font-size: 13px;
        color: white;
        border: none;
        /* height: 48px; */
        background: #008AFF;
        border-radius: 4px;
    }

    .faqs .faq {
        text-align: left;
        width: 100%;
        /* height: 30px; */
        padding: 0.5rem 0.5rem;
        margin: 10px 0px;
        background-color: #F5F5F5;
        border-radius: 2px;
        position: relative;
    }

    .faq-question h6 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.01em;
    }
    .render {
        width: 60px;
        height: 60px;
        border-radius: 7px;
        position: relative;
    }

    .faq-answer p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        white-space: pre-wrap;
        line-height: 25px;
        display: block;
        align-items: flex-end;
        letter-spacing: 0.01em;
        text-align: left;
        color: #2D2D2D;
    }

    .img-services {
        position: relative;
        width: 50px;
        height: 50px;
        top: 10%;
    }
}


@media only screen and (min-width: 350px) and (max-width:490px) {
   
    .services-container {
    
        position: relative;
        display: inline-block;
       
        height: auto;
        justify-content: center;
        padding: 2.5rem 2rem;
      
  
        
    }
   
   
    .take-action {
        position: relative;
        display: block;
        margin: auto;
     
    }
    .take-action img{
        width: 100%;
        position: relative;
        top: 50%;
       
    }
    .taketest {
        position: absolute;
    width: 100px;
    right: 80px;
    color: black;
    top: 24%;
    left: 60%;
    text-align: left;
    }

    .taketest h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 15px;
        color: #2D2D2D;
        text-align: left;
    }
  

    .taketest p{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.01em;
        color: #747474;
        text-align: left;
    
    }

    .taketest button {
        position: relative;
        width: 46px;
        /* height: 48px; */
        /* left: 10px; */
        top: -13px;
        background: #008AFF;
        border-radius: 3px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 7px;
        border: none;
        line-height: 20px;
        color: white;
    }

    .faqs .faq {
        text-align: left;
        width: 100%;
        /* height: 30px; */
        padding: 0.5rem 0.5rem;
        margin: 10px 0px;
        background-color: #F5F5F5;
        border-radius: 2px;
        position: relative;
    }

    .faq-question h6 {
       
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            display: flex;
            align-items: flex-end;
            letter-spacing: 0.01em;
        
    }
}


/* For large screen */


@media only screen and (min-width:1250px) and (max-width:2100px) {

    .services {
        position: relative;
        flex-wrap: wrap;
        flex-wrap: wrap;
        display: flex;
        margin-top: 100px !important;
   
        width: 100% !important;
       
        height: auto;
        justify-content: space-between;
       
        padding: 0 2rem;
    }

    .render-container {
        height: auto;
        width: 350px;
        margin: auto;
    }

    .render-container a{
    display: block;
    width: 140px;
    height: 48px;
    }

    .services-head {
        text-align: left;
        margin-bottom: 0px;
    }


    .text-centerhome p span {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 70px;
        line-height: 85px;
    }

    .text-centerhome p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 70px;
        line-height: 30px;
    }
    .cardhome {
        width: 95%;
        height: 42rem;
        /* background: #EDE8FF; */
        border-radius: 16px;
    }
  
        .cardhome img {
            transition: transform .7s ease-in-out;
            position: relative;
            width: 125%;
            top: 20%;
            left: -30px;
            object-fit: contain;
        }
 
    .details p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.01em;
        color: #747474;
        flex: none;
        order: 1;
        flex-grow: 0;
        width: 90%;
    }
    .text-centerhome {
        line-height: 80px;
        top: 35%;
    }
    .btn-consulation button {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 16px 32px;
        position: static;
        color: white;
        background: #A924E0;
        border-radius: 5px;
        border: none;
        width: -moz-fit-content;
        width: fit-content;
        letter-spacing: 0.5px;
        font-size: 18px;
        
        padding: 16px 32px;
    }
    .services-container {
        position: relative;
        display: block;
        margin: auto;
        height: auto;
        /* border: 1px solid black; */
        justify-content: center;
        padding: 5rem 2rem;
    }

    .services-head h6 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 900;
        font-size: 18px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #A924E0;
        position: relative;
    }
    .services-head h4 {
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    color: #2D2D2D;
    }

    .services-head h1 {
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 72px;
    color: #2D2D2D;
    }

    .services-head p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.01em;
        color: #747474;
    }
    .img-services {
        position: relative;
        width: 35px;
        height: 35px;
        top: 25%;
    }
    .render {
        width: 70px;
        height: 70px;
        border-radius: 7px;
        position: relative;
    }
    .titleservices {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        width: 350px;
        font-size: 22px;
        line-height: 36px;
        color: #2A2A2D;
        top: 15px;
        padding: 15px 0px 5px 0px;

        position: relative;
        text-align: left;
    }

    .description-services {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        width: fit-content;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.01em;
        top: 15px;
        position: relative;
        color: #747474;
        text-align: left;
    }
    
    .btn-services button {
        position: static;
        width: -moz-fit-content;
        width: 140px;
        height: 48px;
        left: 0px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #7A7A7A;
        top: 255px;
        padding: 12px 24px;
        background: #FFFFFF;
        border: 1px solid #7A7A7A;
        border-radius: 4px;
    }
    .taketest {
        position: absolute;
        width: 46%;
        /* right: 160px; */
        color: black;
        padding-left: 70px;
        text-align: left;
        top: 30%;
        right: 0%;
    }
    .taketest h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 48px;
        line-height: 50px;
        color: #2D2D2D;
        text-align: left;
    }
    .taketest p {
        font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    width: 280px;
    margin-top: 20px;
    letter-spacing: 0.01em;
    color: #747474;
    text-align: left;
    }
    .faqs-head h6 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        line-height: 27px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #A924E0;
    }
    .taketest button {
        position: static;
        width: 7.18rem;
        height: 48px;
        left: 0px;
        /* top: 236px; */
        background: #008AFF;
        padding: 12px 25px;
        border-radius: 4px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        border: none;
        line-height: 24px;
        color: white;
    }

    .faq-container {
        position: relative;
     
        display: flex;
        justify-content: flex-start;
        /* padding: 2.5rem 0rem; */
        margin-bottom: 65px;
    }

    /* .faqs-head {
        height: auto;
        width: 99% !important;
        text-align: left;
    } */

    .faqs-head h6 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 42px;
        line-height: 60px;
        color: #2D2D2D;
    }

    .faqs-head h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 50px;
        color: #2D2D2D;
    }

    .faqs-head p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: #747474;
    }

    .faqs-head button {
        align-items: flex-start;
        padding: 6px 8px;
        /* gap: 10px; */
        font-size: 13px;
        width: 77px;
        color: white;
        border: none;
        /* height: 48px; */
        background: #008AFF;
        border-radius: 4px;
    }

    .faqs .faq {
        text-align: left;
        width: 43rem;
        /* height: 30px; */
        /* height: 68px; */
       
        padding: 0.5rem 2rem;
        cursor: pointer;
        margin: 12px;
        background-color: #F5F5F5;
        border-radius: 6px;
        position: relative;
    }

    .faq-question h6 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.01em;
    }

    .faq-answer p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        text-align: left;
        font-size: 14px;
        white-space: pre-wrap;
        line-height: 24px;
        display: block;
        align-items: flex-end;
        letter-spacing: 0.01em;
        color: #2D2D2D;
    }

    .footer-links {
        position: relative;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 0.01em;
        top: 10px;
        color: #807286;
    }
    .footer-distributed .footer-center span {
        display: block;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 36px;
        color: #FFFFFF;
        /* font-weight: normal; */
        /* font-size: 14px; */
        line-height: 2;
    }

    .footer-center {
        width: 50%;
        text-align: left;
        left: 20%;
        top: 5%;
        margin: auto;
        position: relative;
    }

    .footer-distributed .footer-center span:before {
        content: "";
        position: absolute;
        left: 5px;
        top: 35px;
        bottom: 0;
        height: 15px;
        width: 15%;
        border-bottom:4px solid #474149;

      
}
    .call img {
        width: 16px;
        margin: 8px;
    }
    .footer-distributed .footer-company-about span {
        display: block;
        color: #ffffff;
        font-size: 18px;
        font-weight: bold;
        margin: 22px 5px;
    }

 

    .footer-distributed .footer-company-about span:before {
        content: "";
        position: absolute;
    /* left: 5px; */
    top: 18px;
    bottom: 0;
    height: 15px;
    width: 30%;
    border-bottom: 4px solid #474149;
      
}

.footer-distributed .footer-center p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin: 6px 5px;
}


}
    

@media only screen  and (min-width : 2101px) {

    
    .text-centerhome {
        line-height: 45px;
        top: 35%;
    }
    .cardhome {
        width: 100%;
        height: 36rem;
        /* background: #EDE8FF; */
        border-radius: 16px;
    }
    .cardhome img {
        transition: transform .7s ease-in-out;
        position: relative;
        width: 130%;
        top: 12%;
        left: -15px;
    }
    .details p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 30px;
        letter-spacing: 0.01em;
        color: #747474;
        flex: none;
        order: 1;
        flex-grow: 0;
        width: 85%;
    }
    .text-centerhome {
        line-height: 35px;
        top: 35%;
    }
    .btn-consulation button {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
  
        position: static;
        color: white;
        background: #A924E0;
        border-radius: 5px;
        border: none;
        letter-spacing: 0.5px;
        font-size: 18px;
        padding: 16px 32px;
    }

    .services-head h6 {
        font-family: 'Poppins';
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #A924E0;
        position: relative;
    }

    .services-head h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 40px;
        color: #2D2D2D;
    }
    .services-head p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        letter-spacing: 0.01em;
        color: #747474;
    }
    .render {
        width: 60%;
        height: 60px;
        border-radius: 7px;
        position: relative;
        margin: 1.5rem 0rem;
    }
    .titleservices {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        width: 300px;
        font-size: 15px;
        line-height: 36px;
        color: #2A2A2D;
        text-align: left;
    }

    .description-services {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        width: fit-content;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.01em;
        color: #747474;
        text-align: left;
    }
    .btn-services button {
        position: static;
        width: fit-content;
        height: 30px;
        left: 0px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #7A7A7A;
        top: 255px;
        background: #FFFFFF;
        border: 1px solid #7A7A7A;
        border-radius: 4px;
    }

    .img-services {
        position: relative;
        width: 37px;
        height: 37px;
        
        top: 20%;
    }

    .taketest {
        position: absolute;
        width: 25%;
        color: black;
        text-align: left;
        top: 30%;
        right: 15%;
    }

    .taketest h4 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 38px;
        line-height: 34px;
        color: #2D2D2D;
        text-align: left;
    }
    .taketest p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 27px;
        letter-spacing: 0.01em;
        color: #747474;
        text-align: left;
    }

    .taketest button {
        position: static;
        width: 38%;
        /* height: 48px; */
        margin: 1rem 0rem;
        left: 0px;
        /* top: 236px; */
        background: #008AFF;
        border-radius: 4px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        border: none;
        line-height: 24px;
        color: white;
    }

    .faq-container {
        position: relative;
     
        display: flex;
        justify-content: flex-start;
        /* padding: 2.5rem 0rem; */
        margin-bottom: 100px;
    }

    .faqs-head {
        height: auto;
        width: 99% !important;
        text-align: left;
    }

    .faqs-head h6 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 27px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #A924E0;
    }

    .faqs-head h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 50px;
        color: #2D2D2D;
    }

    .faqs-head p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #747474;
    }

    .faqs-head button {
        align-items: flex-start;
        padding: 6px 8px;
        /* gap: 10px; */
        font-size: 16px;
        width: 101px;
        color: white;
        border: none;
        /* height: 48px; */
        background: #008AFF;
        border-radius: 4px;
    }

    .faqs .faq {
        text-align: left;
        width: 38rem;
        /* height: 30px; */
        padding: 0.5rem 1rem;
        margin: 12px;
        background-color: #F5F5F5;
        border-radius: 2px;
        position: relative;
    }

    .faq-question h6 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.01em;
    }

    .faq-answer p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        white-space: pre-wrap;
        text-align: left;
        line-height: 24px;
        display: block;
        align-items: flex-end;
        letter-spacing: 0.01em;
        color: #2D2D2D;
    }

    .footer-links {
        position: relative;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.01em;
        top: 10px;
        color: #807286;
    }
    .footer-distributed .footer-center span {
        display: block;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: #FFFFFF;
        /* font-weight: normal; */
        /* font-size: 14px; */
        line-height: 2;
    }

    .footer-distributed .footer-center span:before {
            content: "";
            position: absolute;
            left: 5px;
            top: 40px;
            bottom: 0;
            height: 15px;
            width: 15%;
            border-bottom:4px solid #474149;

          
    }
    .call img {
        width: 20px;
        margin: 8px;
    }
    .footer-distributed .footer-company-about span {
        display: block;
        color: #ffffff;
        font-size: 24px;
        font-weight: bold;
        margin: 22px 5px;
    }

    .footer-distributed .footer-company-about span:before {
        content: "";
        position: absolute;
        /* left: 5px; */
        top: 93px;
        bottom: 0;
        height: 15px;
        width: 3%;
        border-bottom: 4px solid #474149;
      
    }

    .footer-distributed .footer-center p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        margin: 4px 5px;
    }

}


.elementToFadeInAndOut {
    -webkit-animation: fadein 4s linear forwards;
    animation: fadein 2s linear forwards;
    opacity: 0;
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
}

@keyframes fadein {
  100% { opacity: 1; }
}