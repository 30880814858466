@import url('https://fonts.googleapis.com/css2?family=Poppins');


.btn:focus {
  outline: none;
  box-shadow: none;
}

:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
}

/**
 * Cross-browser styles for explicit focus via 
 * keyboard-based (eg Tab) navigation or the
 * .focus-visible utility class.
 */
:focus,
.focus-visible:focus:not(:focus-visible) {
  outline: 0;
  box-shadow:
    0 0 0 .2rem #fff,
    0 0 0 .35rem #069;
}

.navigation {
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 999;
    
    padding: 0.5rem 0rem;
    background-color: #fff;
    color: black;
    border: 1px solid #EAEAEA;
  }
 
  .navigation-button button{
    position: static;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    width: max-content;
    flex-grow: 0;
    margin: 0 2rem;
    background: #A924E0;
    border-radius: 4px;
    border: hidden;
    padding: 12px 24px;
  }

  .navigation-button button span{
    font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 32px;
margin-left: 10px;

text-align: center;
letter-spacing: 0.01em;
padding: 12px 24px;
color: #FFFFFF;

  }
  .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    padding: 0 2rem;

  }

  .brand-name img{
    cursor: pointer;
  }


  .navigation-menu {
    display: flex;
    margin-left: auto;
    top: 20%;
    width: 100%;
    justify-content: flex-end;
  }


  .navigation-menu ul {
    margin-bottom: 0;
    display: flex;
    padding: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    position: relative;
  }
  .navigation-menu li {
   
    list-style-type: none;
    margin: 0 0.8rem;
    position: relative;
  }
  .navigation-menu li a {

    text-decoration: none;
    display: block;
    width: 100%;
    color: #A5A5A5;
    position: relative;
  }

  



  .navigation-menu li:hover:before {
    content: "";
    position: absolute;
    bottom: -5px;
    background: #008AFF;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    left:calc(50% - 3px);
}


  .navigation-menu li a:hover{
      color: black !important;
  }


  .hamburger {
    
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #A924E0;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
  }

  
 

  .hamburger:hover {
    background-color: #0dcaf0;
  }

  .brand-name img{
    width: 100%;
  }

  /* Responsive for Navbar */


  /* Large screens ----------- */
@media only screen  and (min-width : 1600px) {


  .brand-name img{
    width: auto;
  }

  .navigation {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 999;
    padding: 1rem 3rem;
    background-color: #fff;
    color: black;
    border: 1px solid #EAEAEA;
}

  .navigation-menu ul {
    margin-bottom: 0;
    display: flex;
    padding: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    position: relative;
}

  .navigation-button button {
    position: static;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    width: max-content;
    flex-grow: 0;
    margin: 0 2rem;
    background: #A924E0;
    border-radius: 8px;
    border: hidden;
    padding: 12px 24px;
  }

  

}


@media only screen and (min-width:1250px) and (max-width:1599px) {

  .brand-name img{
    width: fit-content;
  }
  .navigation {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 999;
    /* padding: 1rem 3rem; */
    background-color: #fff;
    color: black;
    border: 1px solid #EAEAEA;
}

  .navigation-menu ul {
    margin-bottom: 0;
    display: flex;
    padding: 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    position: relative;
}

  .navigation-button button {
    position: static;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    width: max-content;
    flex-grow: 0;
    margin: 0 2rem;
    background: #A924E0;
    border-radius: 8px;
    border: hidden;
    padding: 12px 24px;
  }



}




  @media screen and (max-width: 1250px) {
    .hamburger {
      display: block;
    }
    .navigation-button button{
    display: none;
    }
    .brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    padding: 0 0rem;
}
  }

  @media screen and (max-width: 1250px) {
    .navigation-menu ul {
      display: none;
    }
  }

  @media screen and (max-width: 1250px) {
    .navigation-menu ul {
    
      position: absolute;
      top: 55px;
      left: 0;

      flex-direction: column;

      width: 100%;
      height: calc(100vh - 77px);
      background-color: white;
      border-top: 1px solid black;
    }
    .navigation-menu li {
    
      text-align: center;
      margin: 0;
    }
    .navigation-menu li a {
      color: black;
     
      width: 100%;
      padding: 1.5rem 0;
    }
    .navigation-menu li:hover {
      background-color: #A924E0;
    }

    .navigation-menu li a:hover{
      color: rgb(255, 255, 255) !important;
  }

  .navigation-menu li:hover:before {
    content: "";
    position: absolute;
    bottom: 10px;
    background: #008AFF;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    left:calc(50% - 3px);
}
  }

  @media screen and (max-width: 1250px) {
    .navigation-menu ul {
      /* previous styles */
      display: none;
    }
  }

  @media screen and (max-width: 1250px) {
    .expanded{
  
      background-color: rgb(201, 215, 224);
      color: white;

    }

    .navigation-menu.expanded ul{
      display: block;
    z-index: 4;
    height: fit-content;
    border-top: none;
   
      
    }
  }

 