/* .feedback {
    background-color : #31B0D5;
    color: white;
    padding: 10px 20px;
    border-radius: 15px;
    border-color: #46b8da;
  } */
  
  .mybutton {
   
  }

  .mybutton {
    position: fixed;
    bottom: 15px;
    right: 10px;
    border: none;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    display: inline-block;
    border: none;
    background-color: #008aff;
    z-index: 9999;
  }

  .btn-consulation {
    padding: 1rem 0rem;
    width: fit-content;
}

.btn-services {
    text-align: start;
    padding: 2.5rem 0;
    position: relative;
    width: fit-content;
}
  
  .mybutton .feedback {
    visibility: hidden;
    width: 140px;
    text-align: center;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    background-color: #31B0D5;
    color: white;
    padding: 10px 20px;
    top: -35px;
    right: -35px;
    border-radius: 5px;
    border-color: #46b8da;
  }
  
  .mybutton:hover .feedback {
    visibility: visible;
  }

  .mybutton img:hover .feedback {
      visibility: visible;
  }


  .mybutton :hover{
   
  }

  @media only screen and (min-width:1280px){
    .mybutton {
        position: fixed;
        bottom: 15px;
        right: 50px;
        border: none;
        height: 48px;
        z-index: 9999;
        width: 48px;
        border-radius: 50%;
        display: inline-block;
        border: none;
        background-color: #008aff;
    }
  }

