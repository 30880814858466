@import url('https://fonts.googleapis.com/css2?family=Poppins');


.faqs-head span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 20px;
    color: white;
}

.faq-containerfaq{
    position: relative;
   
    margin: auto;

    height: auto;
    display: block;
    justify-content: center;
    padding: 2.5rem 2rem;
    bottom: 40px;
}

.faqs-head-faq{
    height: auto;
    position: relative;
    width: 100%;
    text-align: left;
}

.faqs-head-faq p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #747474;
}





/* --------- */

.faqs {
    position: relative;
    display: inline-block;
}

/* .faqs-head{
    height: auto;
    position: relative;
    width: 100% !important;
    text-align: left;
} */


.faqs-head h3{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 22px;
    color: white;
}

.faqs-head p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #747474;
}


   .faqs-head h6 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 27px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #A924E0;
}



.faqs-head-faq h6{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 28px;
/* identical to box height, or 156% */

display: flex;
align-items: flex-end;
letter-spacing: 0.01em;

color: #2D2D2D;

}

.faqs-head button{
    align-items: flex-start;
    padding: 12px 25px;
    /* gap: 10px; */
    width: 7.18rem;
    height: 48px;
    font-size: 13px;
    color: white;
    border: none;
    /* height: 48px; */
    background: #008AFF;
    border-radius: 4px;
}

.faqspage .faq {
  
    text-align: left;
    width: 100%;
    /* height: 30px; */
    padding: 0.5rem 1rem;
    margin: 10px 0px;
    background-color: #F5F5F5;
    border-radius: 2px;
    position: relative;
}

.faqspage .faq .faq-question {
	position: relative;
	font-size: 20px;
	padding-right: 80px;
    margin-top: 5px;
	transition: all 0.4s ease;
}

.faqspage .faq .faq-question::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%);
	width: 10px;
	height: 30px;
    background-size: 50px 50px;
	background-image: url('../assets//images/Vector.png');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	transition: all 0.4s ease-out;
}

.faqspage .faq .faq-answer {
	opacity: 0;
	max-height: 0;
	overflow-y: hidden;
	transition: all 0.4s ease-out;
}

.faqspage .faq.open .faq-question {
	margin-bottom: 0px;
}

.faqspage .faq.open .faq-question::after {
	transform: translateY(-50%) rotate(180deg);
}

.faqspage .faq.open .faq-answer {
	max-height: 1000px;
	opacity: 1;
}


p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.mid-text h6{
    text-align: left;
    padding-top: 5%;
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 28px;
/* identical to box height, or 156% */

display: flex;
align-items: flex-end;
letter-spacing: 0.01em;

color: #000000;
}



@media screen and (max-width: 768px) {

  

    .coliimagefaq{
        margin-top: 50px !important;
    }

    .faqspage .faq {
        text-align: left;
        width: 360px;
        /* height: 30px; */
        padding: 0.5rem 0.5rem;
        margin: 10px 0px;
        background-color: #F5F5F5;
        border-radius: 2px;
        position: relative;
    }

    .faqspage .faq {
        text-align: left;
        width: 100%;
        /* height: 30px; */
        padding: 0.5rem 0.5rem;
        margin: 10px 0px;
        background-color: #F5F5F5;
        border-radius: 2px;
        position: relative;
    }

    .faqspage .faq {
        text-align: left;
       
        /* height: 30px; */
        padding: 0.5rem 0.5rem;
        margin: 10px 0px;
        background-color: #F5F5F5;
        border-radius: 2px;
        position: relative;
    }

    .faqs-head p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.01em;
        color: #747474;
    }
}

@media only screen and (min-width: 350px) and (max-width:490px) {

    .faqs-head button {
        align-items: flex-start;
        /* padding: 12px 25px; */
        /* gap: 10px; */
        width: 6.18rem;
        height: 35px;
        font-size: 13px;
        color: white;
        border: none;
        /* height: 48px; */
        background: #008AFF;
        border-radius: 4px;
    }
    .faqspage .faq {
        text-align: left;
        width: 100%;
        /* height: 30px; */
        padding: 0.5rem 0.5rem;
        margin: 10px 0px;
        background-color: #F5F5F5;
        border-radius: 2px;
        position: relative;
    }

    .faqs-head p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.01em;
        color: #747474;
    }

    .coliimagefaq{
        margin-top: 50px !important;
    }
   

}

@media screen and (max-width: 500px){
    .box .left{
        display: none;
    }
    .box .middle{
        width:100%;
        padding-left:5px;
        box-sizing: border-box;
        float:initial;
    }
    .box .right{
        width:100%;
        float:initial;
    }

    .coliimagefaq{
        margin-top: 50px !important;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 2100px){



.image-faqpage{
    width: 700px;
    height: 610px;
    object-fit: cover;
    margin-top: 100px;
    margin-left: 100px;
}

.text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 70px;
    padding: 0px 0px;
    line-height: 80px;
}


.coliimagefaq{
   
        width: 35%!important;
        margin: left;
        margin-top: 100px;
        top: 210px;
        left: 45px;
        padding: 48px 0px;
        text-align: left;
        height: auto;
        position: relative;
    
}

.faqs-head p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: #747474;
}
/* .faqs-head {
    height: auto;
    position: relative;
    width: 100%;
    text-align: left;
} */
.faqs-head p {
    width: fit-content;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: #747474;
}
.faqs-head h3 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
    color: white;
}
.faqs-head span{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 20px;
    color: white;
}
}

@media screen  and (min-width:2101x){

    .faqs-head h6 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 27px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #A924E0;
    }

    .faqs-head {
        height: auto;
        position: relative; 
        width: 99% !important;
        text-align: left;
    }

    .faqs-head h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 50px;
        color: #2D2D2D;
    }

    .faqs-head p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #747474;
    }

    .faqs-head button {
        align-items: flex-start;
        padding: 6px 8px;
        /* gap: 10px; */
        font-size: 16px;
        width: 101px;
        color: white;
        border: none;
        /* height: 48px; */
        background: #008AFF;
        border-radius: 4px;
    }
    

    .faq-answer p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        white-space: pre-wrap;
        line-height: 24px;
        display: block;
        align-items: flex-end;
        letter-spacing: 0.01em;
        color: #2D2D2D;
    }
}
    
