.dotoneprice{
    position: absolute;
    width: 8px;
    height: 8px;
    left: -2%;
    bottom: 60%;
    border-radius: 50%;
    background: #6EBECE;

}

.dottwoprice{
    position: absolute;
    width: 15px;
    height: 15px;
    right: -7%;
    top: 90%;
    border-radius: 50%;
    background: #FF5775;
}

.dotthreeprice{
    position: absolute;
    width: 7px;
    height: 7px;
    /* left: 50%; */
    right: -2%;
    top: 35%;
    border-radius: 50%;
    background: #ff5775;
}

.dotfourprice{
    position: absolute;
    width: 15px;
    height: 15px;
    /* left: 0; */
    top: 52%;
    right: -7%;
    border-radius: 50%;
    background: #A9D8FF;
}

.dotfiveprice{
    position: absolute;
    width: 5px;
    height: 5px;
    /* left: 0; */
    top: 88%;
    left: -8%;
    /* right: 0%; */
    border-radius: 50%;
    background: #BA0CDC;
}

.imagePrice img{
    margin-top: 10rem;
    width: 657px;
    height: 504px;
}

.restorelitigationprice{
    position: relative;
    display: flex;
    margin: auto;
  
    top: 100px;
    height: auto;
   
    justify-content: center;
}

.main-pricingcontainer{
    position: relative;
    display: block;
    margin: auto;
    width: 85% !important;
    height: auto;
    justify-content: left;
    padding-top: 76px;
}

.startheading h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 85px;
    width: 580px;
    height: 85px;
}

.startheading h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 85px;
    width: 580px;
    height: 85px;
}

.description-price p{
    width: 1065px;
    height: 31px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.01em;
    color: #000000;
}

.description-pricedetail p{
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 32px;
/* or 178% */
display: list-item;
text-align: justify;
letter-spacing: 0.01em;

color: #2D2D2D;

}



.startheading-plans{
    width: 100%;
    height: auto;
    
    padding-bottom: 76px;

}

.startheading-plans h3{

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    padding: 1rem 0rem;
    font-weight: 400;
    font-size: 48px;
    line-height: 85px;
    line-height: 32px;
    letter-spacing: 0.01em;
    width: fit-content !important;
    height: auto;
    padding-top: 76px;
}

.startheading-plans h6{

width: 309px;
height: 32px;

font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 32px;
/* identical to box height, or 178% */

letter-spacing: 0.01em;

padding-bottom: 56px;

}

.startheading-plans p {
    align-items: left;
    padding: 24px 24px;
 
    width: 100%;
  
    height: 74px;
    text-align: left;
    background: #F5F5F5;
    border-radius: 8px;
}


@media (Max-width: 580px) {
    .description-price p {
        width: 100%;
        height: 31px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.01em;
        color: #000000;
    }
    .startheading h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        width: 100%;
        height: 85px;
    
    }
    .imagePrice img {
        margin-top: 0rem;
        width: 350px;
        height: 300px;
    }
    .text-centerhome p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 50px;
        line-height: 45px;
        text-align: left;
    }
    .description-price p {
        width: 100%;
        height: 85px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 0.01em;
        color: #000000;
    }

    .startheading-plans h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        padding: 1rem 0rem;
        font-weight: 400;
        font-size: 48px;
        line-height: 85px;
        line-height: 70px;
        letter-spacing: 0.01em;
        width: -moz-fit-content !important;
        width: fit-content !important;
        height: auto;
    }

    .startheading-plans p {
        align-items: left;
        padding: 24px 24px;
        width: 100%;
        height: auto;
        text-align: left;
        background: #F5F5F5;
        border-radius: 8px;
    }
    .description-price p {
        width: fit-content;
        height: auto;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.01em;
        color: #000000;
    }
    .restorelitigationprice {
        position: relative;
        display: flex;
        margin: auto;
        height: auto;
        top: 100px;
        width: 100%;
        justify-content: center;
    }
}

@media only screen and (min-width: 581px) and (max-width: 785px){
    .imagePrice img {
        margin-top: -7rem;
        width: 457px;
        height: 457px;
    }
    .startheading h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 34px;
        line-height: 50px;
        width: fit-content;
        height: 85px;
    }
    .description-price p {
        width: fit-content;
        height: auto;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.01em;
        color: #000000;
    }
    .restorelitigationprice {
        position: relative;
        display: flex;
        margin: auto;
        height: auto;
        top: 100px;
        width: 100%;
        justify-content: center;
    }
}