@import url('https://fonts.googleapis.com/css2?family=Poppins');
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}


.divbox a{
    text-decoration: none;
}

.divboxloc a{
    text-decoration: none;
}

.header-container{
   
    /* background-color: aqua; */
    text-align: -webkit-center;
    padding: 3rem 3rem;
}

.colimagecontact{
    width: 50%!important;
    margin: auto;
    top: 300px;
    /* left: 100px; */
    text-align: left;
    height: auto;
    position: relative;

}

.contactsingleform{
    position: relative;
    top: 75px;
}

.row-card{

width: 85%;
height: auto;
justify-content: center;
align-items: center;
background: #FFFFFF;
box-shadow: 0px 17px 22px rgba(29, 29, 29, 0.1);
border-radius: 8px;
padding-bottom: 5rem;
}

.col-card{
    width: 30%;
    height: auto;
    
}

.row-bottom{
width: 85%;
height: 11px;
left: 375px;
background: linear-gradient(269.45deg, #9D82FF 0.12%, #47ACFF 126.22%);
border-radius: 0px 0px 8px 8px;
}

.sliderimage{
    margin: auto;
}
.contactmain{
    width: 1200px;
    height: auto;
}

.contactmain.row {
    margin: auto;
}
.divhead h2{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 29px;
    line-height: 39px;
    color: #2D2D2D;
    flex: none;
    text-align: left;
    margin: 1rem;
    padding: rem 0rem;
    width: 15rem;
    height: auto;
}

.divbox{
color: white;
width: 15rem !important;
height: 2rem;
background: #A9D8FF;
border: 1px solid #FFFFFF;
border-radius: 4px;
text-align: start;
margin: 10px 30px
}

.divboxmsg{
    color: white;
width: 15rem !important;
height: 2rem;
background: #C4B8FE;

border: 1px solid #FFFFFF;
border-radius: 4px;
text-align: start;
margin: 10px 30px
}

.divboxmsg a{
    text-decoration: none;
}

.divboxloc{
    color: white;
width: 15rem !important;
height: 2rem;
background: #F1ADFF;
border: 1px solid #FFFFFF;
border-radius: 4px;
text-align: start;
margin: 10px 30px
}

.divboxloc span{
    margin: 1rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 0px;
    /* padding: 1rem 0rem; */
    width: 12px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.divboxmsg span{
    margin: 1rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.divbox span{
    margin: 1rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.divboxmage{
    width: 7%;
    /* left: 5%; */
    height: auto;
}
.divboxmail{
    width: 14rem;
    height: auto;
}

.contact-footer{
    background: linear-gradient(179.68deg, rgba(234, 228, 255, 0) -3.36%, #EDE8FF 85.75%);
    border-radius: 0px;
    
}


.contact-footer h1{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 37px;
    line-height: 100px;
    display: block;
    align-items: flex-end;
    letter-spacing: 0.01em;
    color: #2D2D2D;

}

.contact-footer h3{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 28px;
/* identical to box height, or 156% */
margin: inherit;
display: flex;
align-items: flex-end;
letter-spacing: 0.01em;
padding-bottom: 0.5rem;
color: #2D2D2D;
}

.contact-footer-row{
    width: 50% !important;
    position: relative;
    margin: auto;
    left: 34%;
    padding-bottom: 1rem;
}

.form-select{
    color:  #ABABAB !important;
    width: 70% !important;
}

.navigation-button-contact{
    padding-bottom: 3rem;
}

.navigation-button-contact button{
    position: static;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    width: max-content;
    flex-grow: 0;
    margin: 0 2rem;
    background: #008AFF;
    border-radius: 4px;
    border: hidden;
    padding: 0 1rem;
  }


.dotonecon{
    position: absolute;
    width: 8px;
    height: 8px;
    left: 10%;
    bottom: 6%;
    border-radius: 50%;
    background: #6EBECE;
    top: 55%;
}
.dottwocon{
    position: absolute;
    width: 15px;
    height: 15px;
    right: 10%;
    top: 85%;
    border-radius: 50%;
    background: #FF99D0;
}
.dotthreecon{
    position: absolute;
    width: 7px;
    height: 7px;
    left: 90%;
    right: 0%;
    top: 50%;
    border-radius: 50%;
    background: #ff5775;
}
.dotfourcon{
    position: absolute;
    width: 15px;
    height: 15px;
    /* left: 0; */
    top: 69%;
    right: 5%;
    border-radius: 50%;
    background: #A9D8FF;
}
.dotfivecon{
    position: absolute;
    width: 5px;
    height: 5px;
    /* left: 0; */
    top: 88%;
    left: 2%;
    /* right: 0%; */
    border-radius: 50%;
    background: #BA0CDC;
}

/* Media Queries */



/* medium screen */

@media (max-width: 481px) { 

    .contactimage {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .contactmain.row {
        margin: auto;
        width: 380px;
        min-width: 380px;
        height: auto;
    }

    .colimagecontact {
        width: 400px !important;
        margin: initial !important;
        top: 170px;
        left: 0px;
        text-align: left;
        height: auto;
        position: relative;
    }
    .contactcard {
        position: relative;
        top: 1rem;
    }

    .divbox {
        color: white;
        width: 85% !important;
        /* padding: 10px 10px; */
        height: 2rem;
        background: #A9D8FF;
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        text-align: start;
        
    }

    .contact-footer h3 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        margin: inherit;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.01em;
        padding-bottom: 0.5rem;
        color: #2D2D2D;
    }
    .colimage {
        width: -moz-fit-content !important;
        width: fit-content !important;
        display: block;
        margin: auto;
        left: 0px;
        text-align: center;
        height: auto;
        position: relative;
    }
  
    .divboxmsg {
        color: white;
        width: 85% !important;
        height: 2rem;
        background: #C4B8FE;
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        text-align: start;
        
    }

    .divboxloc {
        color: white;
        width: 85% !important;
        height: 2rem;
        background: #F1ADFF;
        border: 1px solid #FFFFFF;
        border-radius: 4px;
        text-align: start;
        
    }

    .contact-footer-row {
        width: 98% !important;
        position: relative;
        margin: auto;
        left: 2rem;
        padding-bottom: 1rem;
    }

    .form-select {
        color: #ABABAB !important;
        width: 90% !important;
    }

    .divhead h2 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 39px;
        color: #2D2D2D;
        flex: none;
        text-align: left;
        margin: 1rem;
        padding: rem 0rem;
        width: 24rem;
        height: auto;
    }
    .contact-footer h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 100px;
        display: block;
        align-items: flex-end;
        letter-spacing: 0.01em;
        color: #2D2D2D;
    }
}



/* large screen */

@media only screen and (min-width: 482px) and (max-width: 785px){


    .contactmain.row {
        margin: auto;
        width: 50%;
    }
    .contactcard {
        position: relative;
        top: 1rem;
    }

    .form-select {
        color: #ABABAB !important;
        width: 90% !important;
    }

    .row-bottom {
        width: 70%;
        height: 11px;
        left: 375px;
        background: linear-gradient(269.45deg, #9D82FF 0.12%, #47ACFF 126.22%);
        border-radius: 0px 0px 8px 8px;
    }

    .colimagecontact {
        width: 100%!important;
        margin: auto;
        top: 0px;
        left: 0px;
        text-align: left;
        height: auto;
        position: relative;
    }

    .sliderimage img{
        margin-left: -550px;
    }
    .colimage {
        width: -moz-fit-content !important;
        width: fit-content !important;
        display: block;
        margin: auto;
        left: 0px;
        text-align: center;
        padding: 48px 0px;
        height: auto;
        position: relative;
    }

    .row-card {
        display: block;
        width: 70%;
        height: auto;
        /* justify-content: center; */
        /* align-items: center; */
        background: #FFFFFF;
        box-shadow: 0px 17px 22px rgb(29 29 29 / 10%);
        border-radius: 8px;
        padding-bottom: 5rem;
    }
    .divboxmage{
        width: 7%;
        /* left: 5%; */
        height: auto;
    }

    .contact-footer-row {
        width: 70% !important;
        position: relative;
        margin: auto;
        left: 6rem;
        padding-bottom: 1rem;
    }

    .contact-footer h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 100px;
        display: block;
        align-items: flex-end;
        letter-spacing: 0.01em;
        color: #2D2D2D;
    }
 }


 
@media only screen and (min-width: 785px) and (max-width: 1280px){

    .contact-footer-row {
        width: 50% !important;
        position: relative;
        margin: auto;
        left: 34% !important;
        padding-bottom: 1rem;
    }

    .colimagecontact {
        width: 40%!important;
        margin: auto;
        top: 150px;
        /* left: 100px; */
        text-align: left;
        height: auto;
        position: relative;
    }
    
    

}

@media only screen and (min-width: 1280px) and (max-width: 2100px){

    .navigation-button-contact {
        padding-bottom: 3rem;
        width: 232px !important;
        height: auto;
        margin: auto;
        position: relative;
        left: -50px;
        height: 52;
    }
    .row-bottom {
        width: 1200px;
        height: 11px;
        left: 375px;
        background: linear-gradient(269.45deg, #9D82FF 0.12%, #47ACFF 126.22%);
        border-radius: 0px 0px 8px 8px;
    }
 .contactimage{
    width: 618px;
    height: 824px;
 }

 .colimagecontact {
    width: 50%!important;
    margin: auto;
    top: 300px;
    left: 50px;
    text-align: left;
    height: auto;
    position: relative;
}

.row-card {
    width: 1200px;
    height: 600px;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 17px 22px rgb(29 29 29 / 10%);
    border-radius: 8px;
    padding-bottom: 5rem;
}
.divboxmail {
    width: 22rem;
    height: 370px;
}
.divhead h2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 29px;
    line-height: 39px;
    font-size: 48px;
    line-height: 57px;
    color: #2D2D2D;
    flex: none;
    text-align: left;
    margin: 2rem;
    padding: rem 0rem;
    width: 26rem;
    height: auto;
}
.divbox {
    color: white;
    width: 25rem !important;
    height: 4rem;
    background: #A9D8FF;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    text-align: start;
    padding: 20px 20px;
    cursor: pointer;
}
.divbox span {
    margin: 1rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.divboxmsg {
    color: white;
 
    width: 25rem !important;
    height: 3.5rem;
    background: #C4B8FE;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    text-align: start;
    padding: 10px 10px;
    cursor: pointer;
}

.divboxloc {
    color: white;
    width: 15rem !important;
    height: 2rem;
    width: 25rem !important;
    height: 3.5rem;
    background: #F1ADFF;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    text-align: start;
    margin: 10px 30px;
    padding: 10px 10px;
    cursor: pointer;
}

.divboxloc a{
    text-decoration: none;
}

.divbox a{
    text-decoration: none;
}

.divboxloc span {
    margin: 1rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* padding: 1rem 0rem; */
    width: 12px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.divboxmsg span {
    margin: 1rem;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #FFFFFF;
}

.contact-footer h1 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 132px;
    display: block;
    align-items: flex-end;
    letter-spacing: 0.01em;
    color: #2D2D2D;
}

.navigation-button-contact button {
    position: static;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    width: max-content;
    flex-grow: 0;
    margin: 0 2rem;
    background: #008AFF;
    border-radius: 4px;
    border: hidden;
    padding: 10px 28px;
}

.leftcontact{
    margin-left:67px;
}

.contactmain {
    width: 1200px;
    height: auto;
}


}




.form-control.is-valid, .was-validated .form-control:valid {
    background-image: none !important;
    border-color: #ced4da;
    padding-right: 0.75em;
}


.hideform{
    display: none;
}
